import React, {useState, useEffect} from 'react';
import { useParams, Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";
import Icon from '../../atoms/Icon/Icon';
import Checkbox from '../../atoms/CheckboxInput/CheckboxInput';
import Button from '../../atoms/Button/Button';
import useAuth from "../../context-providers/Auth";
import { useExamSharingState } from '../../providers/examSharing';
import uploadFilePopupHOC from '../../hocs/uploadFilePopupHOC';
import loadExamAssocAttachments from '../../services/loadExamAssocAttachments';
import changeStatusSharingDocument from '../../services/changeStatusSharingDocument';
import changeStatusGeneralDocument from '../../services/changeStatusGeneralDocument';
import uploadDocumentList from '../../services/uploadDocumentList';
import changeStatusGeneralDocWithoutAssoc from '../../providers/examSharing/actions/changeStatusGeneralDocWithoutAssoc';

import './SharingListWidget.css';

const UploadButton = uploadFilePopupHOC(Button);

const SharingListWidget = ({ t: __, closeWidget, onSuccesfulUpload, trackEvent }) => {
  const { generalDocuments, uploadedDocuments } = useExamSharingState();
  const { siteId } = useAuth();
  const { examId } = useParams();
  const [isGeneralDoc, setIsGeneralDoc] = useState(true);
  const [isUploadedDoc, setIsUploadedDoc] = useState(true);
  const handleGeneralDocClick = () => setIsGeneralDoc((value => !value));

  const handleUploadDocClick = () => setIsUploadedDoc((value => !value));
  const cancelEventClick = (event) => event.stopPropagation();

  const toggleGeneralDoc = (item) => {
    if (!item.id) {
      changeStatusGeneralDocWithoutAssoc({...item, active: !item.active});
    } else {
      changeStatusGeneralDocument(item);
    }
    trackEvent('user_selects_or_unselects_a_general_document')
  }

  const sendDocuments = async({ files, addServerError, addStatusFile }) => {
    try {
      await uploadDocumentList({ examId, siteId, files, addStatusFile, addServerError });
      trackEvent('user_uploads_a_document')
    } catch (err) {}
  }

  useEffect(() => {
    const closeList = () => {
      closeWidget();
    }
    window.addEventListener('click', closeList)
    return () => {
      window.removeEventListener('click', closeList)
    }
  }, [])

  return (
    <>
    <div className="examination-sharing-list-widget" onClick={cancelEventClick}>
      {(<h5 className="examination-sharing-list-widget-title">
        <span>{__('examination.sharingListWidget.generalDocuments')}</span>
        <Icon name={isGeneralDoc? "down" : "up"} onClick={handleGeneralDocClick} />
      </h5>)}
      {isGeneralDoc && (
        generalDocuments.length > 0 ? (
          generalDocuments.map(item => (
            <div key={item.id || `id-${item.attachment_id}`} className="examination-sharing-list-widget-list-item">
              <Checkbox
                checked={item.active}
                onClick={() => toggleGeneralDoc(item)}
              />
              <span>{item.attachment.title}</span>
            </div>
          ))
        ):(
          <div className="examination-sharing-list-widget-list-item add-document-label">
            <Link to={`/sharing-preferences`}>
              {__('examination.sharingListWidget.addDocumentToYourLibraryLink')}
            </Link>
            {' '}
            <span>
              {__('examination.sharingListWidget.addDocumentToYourLibraryText')}
            </span>
          </div>
        ))}
      <h5 className="examination-sharing-list-widget-title">
        <span>{__('examination.sharingListWidget.uploadedDocument')}</span>
        <Icon name={isUploadedDoc ? "down" : "up"} onClick={handleUploadDocClick}/>
      </h5>
      {isUploadedDoc && uploadedDocuments.map(item => (
        <div key={item.id} className="examination-sharing-list-widget-list-item">
          <Checkbox
            checked={item.active}
            onClick={() => {
              changeStatusSharingDocument(item)
              trackEvent('user_selects_or_unselects_a_uploaded_document')
            }}
          />
          <span>{item.attachment.title}</span>
        </div>
    ))}
      <div className="examination-sharing-list-widget-upload-button-box">
        <UploadButton
          icon="upload-file"
          variant="outline"
          label={__('examination.sharingListWidget.uploadButton')}
          onSuccesfulUpload={onSuccesfulUpload}
          submit={sendDocuments}
          loadNewList={() => loadExamAssocAttachments(examId)}
        />
      </div>
    </div>
    </>
  )
}

export default withTranslation()(SharingListWidget);
