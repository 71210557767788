import {useCallback, useContext, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import UserApi from "../../services/user";
import ResourceApi from "../../services/resource";
import {AppContext} from "../../context-providers/App";
import useAuth from "../../context-providers/Auth";
import LookupApi from "../../services/lookup";
import "./PreselectOptions.css"
import PrintingPreselectOption from "./PrintingPreselectOption";

const PreselectOptions = ({t: __}) => {
  const appContext = useContext(AppContext);
  const { config: userConfig } = useAuth();
  const [preselectConfiguration, setPreselectConfiguration] = useState(null);
  const [allSlides, setAllSlides] = useState([]);

  useEffect(() => {
    UserApi.getUserSharingPreselection().then((resp) => setPreselectConfiguration(resp.data.preselection));
  }, []);

  const getAllSlides = useCallback(async () => {
    const { data: { data: slides } } = await LookupApi.listImagePlane()
    setAllSlides(slides.filter(slide => ['picture', 'other'].includes(slide.type)))
  }, [LookupApi.listImagePlane])

  useEffect(() => {
    getAllSlides()
  }, [getAllSlides]);

  const sharingExamNd = preselectConfiguration?.sharing_exam_nd ?? "all";
  const printingExamNd = preselectConfiguration?.printing_exam_nd ?? "none";
  const sharingExamProtocol = preselectConfiguration?.sharing_exam_with_protocol ?? "all";
  const printingExamProtocol = preselectConfiguration?.printing_exam_with_protocol ?? "template";

  const updatePreselectField = (fields) => {
      UserApi.setUserSharingPreselection(fields)
        .then((resp) => setPreselectConfiguration(resp.data.preselection));
  };

  const getActivePresets = () =>
    appContext.displayedPresets
      .map((x) => appContext.allPresets.find(p => p.id === x))
      .filter(x => !!x);

  const generateOptions = (value, withTemplate = false) => {
    const options = withTemplate ? [
      {label: __("sharingPreferences.preselect.option.none"), value: "none"},
      {label: __("sharingPreferences.preselect.option.template"), value: "template"},
      {label: __("sharingPreferences.preselect.option.all"), value: "all"},
    ] : [
      {label: __("sharingPreferences.preselect.option.none"), value: "none"},
      {label: __("sharingPreferences.preselect.option.all"), value: "all"},
    ];

    return !value ? [
        {label: __("sharingPreferences.preselect.option.notSet"), value: null},
        ...options
      ]
      : options;
  };

  const handlePreselectOptionUpdate = (presetId, value) => {
    ResourceApi.updateProtocolPrintingTemplate(presetId, value).then(_x => {
      UserApi.getUserSharingPreselection().then((resp) => setPreselectConfiguration(resp.data.preselection));
    })
  }

  return <>
    {appContext.protocolsEnabled ? (<>
      <div className="row">
        <h4>{__("sharingPreferences.preselect.title.printed")}</h4>
        <small className="sub-title">{__("sharingPreferences.preselect.description.printed")}</small>
      </div>
      <div className="row">
        {getActivePresets().map(preset =>
          <div className="w4" key={`widget-${preset.id}`}>
            <div>{preset?.name}</div>
            <div>
              <PrintingPreselectOption
                preset={preset}
                fullConfig={preselectConfiguration}
                userConfig={userConfig}
                allSlides={allSlides}
                onSave={(value) => handlePreselectOptionUpdate(preset.id, value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="row spacer"></div>

      <div className="row">
        <h4>{__("sharingPreferences.preselect.title.shared")}</h4>
        <small className="sub-title">{__("sharingPreferences.preselect.description.shared")}</small>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.examWithoutProtocol")}</div>
        <div className="w8">
          <SelectInput
            value={sharingExamNd}
            onChange={(value) => updatePreselectField({"sharing_exam_nd": value})}
            options={generateOptions(sharingExamNd)}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.examWithProtocol")}</div>
        <div className="w8">
          <SelectInput
            value={sharingExamProtocol}
            onChange={(value) => updatePreselectField({"sharing_exam_with_protocol": value})}
            options={generateOptions(sharingExamProtocol)}
          />
        </div>
      </div>
    </>) : (<>
      <div className="row">
      <h3>{__("sharingPreferences.preselect.title.printedAndShared")}</h3>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.printedImages")}</div>
        <div className="w8">
          <SelectInput
            value={printingExamNd}
            onChange={(value) => updatePreselectField({"printing_exam_nd": value})}
            options={generateOptions(printingExamNd)}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.sharedImages")}</div>
        <div className="w8">
          <SelectInput
            value={sharingExamNd}
            onChange={(value) => updatePreselectField({"sharing_exam_nd": value})}
            options={generateOptions(sharingExamNd)}
          />
        </div>
      </div>
    </>)}
  </>
};
export default withTranslation()(PreselectOptions);
