import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import SelectInput from "../../../atoms/SelectInput/SelectInput";
import { positionIdToRotation } from '../../../config';
import { AppContext } from "../../../context-providers/App";
import { ExaminationContext } from "../../../context-providers/Examination";
import useAuth from "../../../context-providers/Auth";
import "./FetusPanel.css";
import Icon from "../../../atoms/Icon/Icon";
import FetusSexHandler from "../../../components/MedicalHistoryInput/FetusSexHandler";
import ButtonGroup from "../../../atoms/ButtonGroup/ButtonGroup";

const ExaminationViewFetusPanelDetection = withTranslation()(({ t: __, children, icons = [] }) => {
  const [openBaloon, setOpenBaloon] = useState(false);

  const onCloseHandler = useCallback(() => {
    setOpenBaloon(false);
  }, [setOpenBaloon]);

  useEffect(() => {
    if (openBaloon) {
      window.addEventListener('click', onCloseHandler, false);
    } else {
      window.removeEventListener('click', onCloseHandler, false);
    }
  }, [openBaloon]);

  useEffect(() => {
    return () => window.removeEventListener('click', onCloseHandler, true);
  }, []);

  return (
    <div className="examination-live-fetus-panel_sex_detection">
      <div className="detected-by" onClick={() => setOpenBaloon(open => !open)}>
        {icons.map(icon =>
          <span key={icon.icon} className={icon.unusual ? 'unusual' : ''}><Icon name={icon.icon} /></span>
        )}
      </div>
      {openBaloon && (
        <div className="dropdown">
          {children}
        </div>
      )}
    </div>
  );
});

const ExaminationViewFetusPanel = ({
  t: __,
  currentPreset,
  fetalSexField,
  sexConfirmedBy,
  sexContradictedBy,
  placentaPositionField,
  placentaPositionConfirmedBy,
  placentaPositionContradictedBy,
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();

  const [currentCategory, setCurrentCategory] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(false);
  const [fetusPositionCategories, setFetusPositionCategories] = useState([]);
  const [fetusRotation, setFetusRotation] = useState({
    rotation: 0,
    flip: false,
  });

  useEffect(() => {
    if (!!appContext.fetusPositions.length && currentPreset) {
      if (!examinationContext.examination.fetus_position_id) {
        const tmpFetusPositionId = 1;
        setFetusRotation(positionIdToRotation[tmpFetusPositionId]);
        examinationContext.updateExamination({
          ...examinationContext.examination,
          template_id:
            (
              appContext.examinationTemplatesConfiguration
                .find(templateDef =>
                  templateDef.examination_type === "screening"
                  && templateDef.examination_preset_id === currentPreset
                  && templateDef.position_id === tmpFetusPositionId)
              ?? appContext.examinationTemplatesConfiguration
                .find(templateDef =>
                  templateDef.examination_type === "screening"
                  && templateDef.examination_preset_id === currentPreset
                  && templateDef.position_id === null)
            ).examination_template_id,
          fetus_position_id: tmpFetusPositionId
        });
      } else {
        examinationContext.updateExamination({
          ...examinationContext.examination,
          template_id:
            (appContext.examinationTemplatesConfiguration
              .find(templateDef => templateDef.examination_type === "screening" && templateDef.examination_preset_id === currentPreset && templateDef.position_id === examinationContext.examination.fetus_position_id) ??
              appContext.examinationTemplatesConfiguration
                .find(templateDef => templateDef.examination_type === "screening" && templateDef.examination_preset_id === currentPreset && templateDef.position_id === null))
              .examination_template_id
        });
      }
      if (examinationContext.examination?.fetus_position_id) {
        setFetusRotation(positionIdToRotation[examinationContext.examination.fetus_position_id]);
      }
    }
  }, [appContext.fetusPositions, examinationContext.examination.fetus_position_id, currentPreset]);

  
  useEffect(() => {
    const categories = appContext.fetusPositions?.reduce((positions, position) => {
      const category = getCategoryFromLabel(position.label[currentLanguage]);
      if (!category || positions.includes(category)) return positions;
      return [...positions, category];
    }, []);
    setFetusPositionCategories(categories || []);
  }, [appContext.fetusPositions]);

  useEffect(() => {
    setCurrentCategory(getCategoryFromLabel(appContext.fetusPositions.find(position => position.id === examinationContext.examination.fetus_position_id)?.label[currentLanguage]));
    setCurrentPosition(examinationContext.examination.fetus_position_id);
  }, [examinationContext.examination.fetus_position_id, fetusPositionCategories]);

  const getCategoryFromLabel = (label) => label?.split("–")[0]?.trim();

  const fetusPositionCategoryPositions = useMemo(() => (
    appContext.fetusPositions.filter(position => position.label?.[currentLanguage]?.indexOf(currentCategory) === 0).map(position => ({ ...position, label: position.label?.[currentLanguage]?.split("–")[1]?.trim() }))
  ), [fetusPositionCategories, currentCategory]);

  const setPositionCategory = (category) => {
    if (category !== currentCategory) {
      setCurrentCategory(category);
      const positionId = appContext.fetusPositions.find(position => position.label[currentLanguage].indexOf(category) === 0)?.id;
      if (!!positionId) onFetusPositionChange(positionId);
    }
  }

  const onFetusPositionChange = (value) => {
    examinationContext.updateExamination({
      ...examinationContext.examination,
      template_id:
        (appContext.examinationTemplatesConfiguration
          .find(templateDef => templateDef.examination_type === "screening" && templateDef.examination_preset_id === currentPreset && templateDef.position_id === value) ??
          appContext.examinationTemplatesConfiguration
            .find(templateDef => templateDef.examination_type === "screening" && templateDef.examination_preset_id === currentPreset && templateDef.position_id === null))
          .examination_template_id,
      fetus_position_id: value
    });
  };

  const setFetalSex = (sex) => {
    const field = examinationContext.medicalHistoryItems?.["medicalexam.fetus.sex"];
    field.detected_by = "user";
    // delete field.source;
    // delete field.source_version;
    // delete field.detected_by_instance_id;
    examinationContext.updateMedicalHistory(field, null, sex);
  }

  const setPlacentaPosition = (position_id) => {
    examinationContext.updateExamination({
      placenta_position_id: position_id,
      placenta_position_id_source: "user",
    });
  }

  const getIconByDetectionAuthor = (detectedBy) => {
    return {
      "vision-ai": "ai-detection",
      "vision_ai": "ai-detection",
      "ocr": "ocr",
      "sonio_ocr": "ocr",
      "user": "manual",
    }[detectedBy];
  }

  const getFetusSexIcons = () => {
    const icons = [];
    if (fetalSexField?.detected_by === "user") {
      icons.push({
        icon: "manual",
        unusual: false,
      })
    }
    if (sexConfirmedBy.length || sexContradictedBy.length) {
      icons.push({
        icon: "ai-detection",
        unusual: !!sexContradictedBy.length,
      })
    }
    return icons;
  }

  const getPlacentaLocationIcons = () => {
    const icons = [];
    if (placentaPositionField?.detected_by === "user") {
      icons.push({
        icon: "manual",
        unusual: false,
      })
    }

    if (placentaPositionConfirmedBy.length || placentaPositionContradictedBy.length) {
      let confimedByOcr = placentaPositionConfirmedBy.filter(p => p.predictions.find(pr => pr.type === "sonio_ocr"));
      let confirmedByAi = placentaPositionConfirmedBy.filter(p => p.predictions.find(pr => pr.type === "quality_evaluation"));

      let contradictedByOcr = placentaPositionContradictedBy.filter(p => p.predictions.find(pr => pr.type === "sonio_ocr"));
      let contradictedByByAi = placentaPositionContradictedBy.filter(p => p.predictions.find(pr => pr.type === "quality_evaluation"));

      if (confimedByOcr.length || contradictedByOcr.length) {
        icons.push({
          icon: "ocr",
          unusual: !!contradictedByOcr.length
        })
      }
      if (confirmedByAi.length || contradictedByByAi.length) {
        icons.push({
          icon: "ai-detection",
          unusual: !!contradictedByByAi.length
        })
      }
    }

    return icons;
  }

  const getOppositeSex = (sex) => {
    return sex === "male" ? "female" : "male";
  }

  const getPlacentaLabelByInstance = (instance) => {
    const placentaLocationId = instance.qc_prediction?.data?.[0].placenta_location?.id;
    return appContext.placentaPositions.find(position => position.id === placentaLocationId)?.label[currentLanguage];
  }

  return (
    <div className={`examination-live-fetus-panel-container ${isFeatureFlagEnabled("sonio.routine") ? "" : "hidden-fetus-position"} ${examinationContext.fetusSexVisibility === "hidden" ? "hidden-fetus-sex" : ""}`}>
      {examinationContext.fetusSexVisibility !== "hidden" && (
        <div className="examination-sidebar-fetus_sex">
          <div className="examination-sidebar-fetus_heading">
            <h2>{__("report.fetusSex")}</h2>
            {!!fetalSexField?.detected_by && (
              <ExaminationViewFetusPanelDetection icons={getFetusSexIcons()}>
                {fetalSexField?.detected_by === "user" && <div className="examination-live-fetus-panel_source">
                  <Icon name={getIconByDetectionAuthor(fetalSexField?.detected_by)} />&nbsp;
                  {__("report.fetusSex.detectedBy.user", {value: __("report.fetusSex." + fetalSexField?.value)})}
                </div>}
                {!!sexConfirmedBy.length && <div className="examination-live-fetus-panel_confirmed">
                  {sexConfirmedBy.filter((instance, index) => sexConfirmedBy.findIndex(i => i.slideId === instance.slideId) === index).map(instance => <div key={instance.id}>
                    <Icon name="ai-detection" />&nbsp;
                    {__((fetalSexField?.detected_by === "user" ? "report.fetusSex.confirmedBy" : "report.fetusSex.setBy") + (sexContradictedBy.length === 1 ? '.single' : ''), {number_images: sexConfirmedBy.length, value: __(`report.fetusSex.${getOppositeSex(fetalSexField?.value)}`), view_name: instance.instance_view.label[currentLanguage]})}
                  </div>)}
                </div>}
                {!!sexContradictedBy.length && <div className="examination-live-fetus-panel_contradicted">
                  {sexContradictedBy.filter((instance, index) => sexContradictedBy.findIndex(i => i.slideId === instance.slideId) === index).map(instance => <div key={instance.id}>
                    <Icon name="ai-detection" />&nbsp;
                    {__((fetalSexField?.detected_by === "user" ? "report.fetusSex.contradictedBy" : "report.fetusSex.setBy") + (sexContradictedBy.length === 1 ? '.single' : ''), {number_images: sexContradictedBy.length, value: __(`report.fetusSex.${getOppositeSex(fetalSexField?.value)}`), view_name: instance.instance_view.label[currentLanguage]})}
                  </div>)}
                </div>}
              </ExaminationViewFetusPanelDetection>
            )}
          </div>
          <FetusSexHandler showPatientChoice={false}>
            <ButtonGroup
              options={[
                {value: "unknown", label: __("report.fetusSex.unknown")},
                {value: "male", icon: "male", iconAfter: "ai-detection", label: __("report.fetusSex.male")},
                {value: "female", icon: "female", iconAfter: isFeatureFlagEnabled("sonio.detect_v2") ? "ai-detection" : false, label: __("report.fetusSex.female")},
              ]}
              value={fetalSexField?.value || "unknown"}
              onChange={setFetalSex}
              />
          </FetusSexHandler>
        </div>
      )}

      <div className="examination-sidebar-fetus_placenta">
        <div className="examination-sidebar-fetus_heading">
          <h2>{__("report.placentaLocation")}</h2>
          {!!placentaPositionField.detected_by && (
            <ExaminationViewFetusPanelDetection icons={getPlacentaLocationIcons()} detectionIcon={getIconByDetectionAuthor(placentaPositionField?.detected_by)}>
              {placentaPositionField?.detected_by === "user" && <div className="examination-live-fetus-panel_source">
                <Icon name={getIconByDetectionAuthor(placentaPositionField?.detected_by)} />&nbsp;
                {__("report.fetusSex.detectedBy.user", {value: appContext.placentaPositions.find(position => position.id === examinationContext.examination?.placenta_position_id)?.label[currentLanguage]})}
              </div>}
              {!!placentaPositionConfirmedBy.length && <div className="examination-live-fetus-panel_confirmed">
                {placentaPositionConfirmedBy.filter((instance, index) => placentaPositionConfirmedBy.findIndex(i => i.slideId === instance.slideId) === index).map(instance => <div key={instance.id}>
                  <Icon name="ai-detection" />&nbsp;
                  {__((placentaPositionField?.detected_by === "user" ? "report.fetusSex.confirmedBy" : "report.fetusSex.setBy") + (sexContradictedBy.length === 1 ? '.single' : ''), {number_images: sexConfirmedBy.length, value: getPlacentaLabelByInstance(instance), view_name: instance.instance_view.label[currentLanguage]})}
                </div>)}
              </div>}
              {!!placentaPositionContradictedBy.length && <div className="examination-live-fetus-panel_contradicted">
                {placentaPositionContradictedBy.filter(instance => instance.qc_prediction?.data?.[0].placenta_location?.id).map(instance => <div key={instance.id}>
                  <Icon name="ai-detection" />&nbsp;
                  {__((placentaPositionField?.detected_by === "user" ? "report.fetusSex.contradictedBy" : "report.fetusSex.setBy") + '.single', {number_images: placentaPositionContradictedBy.length, value: getPlacentaLabelByInstance(instance), view_name: instance.instance_view.label[currentLanguage]})}
                </div>)}
              </div>}
            </ExaminationViewFetusPanelDetection>
          )}
        </div>
        <SelectInput
          options={[
            {label: __("examination-plane.qualityCriteria.notValid"), value: ""},
            ...(appContext.placentaPositions?.map(position => ({label: position.label[currentLanguage], value: position.id, icon: position.detectable ? "ai-detection" : false})) || [])
          ]}
          value={examinationContext.examination?.placenta_position_id || ""}
          onChange={(position_id) => setPlacentaPosition(position_id)}
        />
      </div>

      {isFeatureFlagEnabled("sonio.routine") && (
        <div className="examination-sidebar-fetus_position">
          <h2>{__("examination.position")}</h2>
          <div className={`examination-sidebar-fetus rotate-${fetusRotation?.rotation} ${fetusRotation?.flip ? "flip" : ""}`}>
            <svg
              version="1.1"
              x="0px"
              y="0px"
              width="500.3px"
              height="511.3px"
              viewBox="0 0 500.3 511.3">
              <circle
                className="fetus-path"
                cx="250.2"
                cy="255.6"
                r="250.2"
              />
              <path
                className="fetus-path"
                d="M403.2,400.8c-2.4,0-29.2-0.7-41.7-4.6c-12.4-3.8-14.7-6.4-18-15.4c-3.3-8.9-2.7-12-5.8-17.2c-3.2-5.3-10.9-6.4-11.7-5.3 c-1.2,1.5-1,7.8-1,21.5s-1.2,29.4,1,36.9s5.2,15.7,12.6,15.6c7.4-0.1,24.5-6.4,30.9-7.5c6.4-1.1,26.3-5.7,34.1-10.7 c7.8-4.9,6.5-9.3,4.7-11.1C406.5,401.2,405.6,400.8,403.2,400.8z"
              />
              <path
                className="fetus-path"
                d="M304.9,257.8c-1.7-1,0.7-5.4-0.4-7.5c-1.1-2.2-4.8-4.3-7.9-0.1c-3.1,4.2-4.9,14.6-6.6,18c-1.6,3.3-4.7,8.3-6.2,9.8 s-2.3,1.6-2.8,2.5c-0.5,0.9-7.7,18.8-6.5,19.3c1.6,0.7,6.1-4.7,11.6-6.7c5.5-2.1,9.7-3.1,16.4-1.9c6.7,1.3,11.5,4.1,20.8,3.8 c9.3-0.3,13.6-1,14.8-3.9c1.2-2.8-1.7-3.8-3.4-4.4c-1.6-0.6-2.7-1.4-2.4-3c0.3-1.6,4.7-1.9,7-2.7c2.3-0.8,2.9-3.8,1.3-4.9 c-1.6-1.2-6.5-0.3-8.5-2.1c-2-1.7,0.3-3.5,2.5-4s5.3-1.2,6.2-3.2c0.9-2-0.6-5.7-4.1-5.7s-9.2,0.8-10.2,0.8c-0.9,0-2-1.1,0-3 c1.9-1.9,6-4.3,6.2-6.3c0.3-2-1-5.1-4.4-5.1c-3.5-0.1-9.2,4.8-13.9,6.9S306.6,258.8,304.9,257.8z"
              />
              <path
                className="fetus-path"
                d="M430,314c-3.1,0.2-20.8,11.4-28.9,15.6c-8.1,4.2-23.5,8.2-27.5,8.3s-22.2-15.5-37.2-26.4c-15-10.9-29-15.4-37.7-15.4 s-16,5.3-21.9,12.7c-6,7.4-16.3,27.1-22.4,49c-6,21.9-7.6,41.7-9.3,46.2c0,0.1-0.1,0.2-0.1,0.3c-0.9-1.7-1.6-14-0.5-24.3 c1.3-11.1,8.5-35,7-36s-5.4,2.1-23.6-0.5l-0.1,0.1c-1-0.1-2.2-0.3-2.8-0.5c-10.2-1.6-23.6-6.9-34.7-11.9 c-11.3-5.1-20.2-9.9-21.3-10.4c0,0-5.2-2.8-3.6-4.6h0c1.4,0.4,14.7,6.2,23.9,10.1c9.2,3.9,33.3,11.2,43.2,10.8 c9.8-0.4,19.2-2.6,26.2-17c7.1-14.4,24.3-62.2,30.3-70.6c6-8.4,13.6-16.1,21.7-19c8-2.8,6.8-6.6,6.5-8c-0.3-1.4-3.6-1.7-6-1.2 c-2.4,0.5-5.1,0.6-5.2-0.3c-0.1-0.9,1-3.4,3.5-4.5c2.5-1,5.6-2.7,5.7-4.6c0.1-1.9-2-5.3-4.5-4.7c-2.5,0.6-8.6,5.8-10.2,4 c-1.6-1.7,6.7-6.7,7.2-10.8c0.5-4.1-2.5-5.1-4.5-4.3c-2,0.7-9.4,8-12,6.2c-2.6-1.8,2.1-7.4,2.4-10.7c0.3-3.2-2.5-4.8-5.3-3.8 s-6.5,6.2-8.5,8.9c-2,2.7-8.3,13.5-9.9,13.1c-1.6-0.4-2.4-7.8-6.2-8.6c-3.8-0.8-5.8,6-5.6,9.1c0.3,3.1,3.6,13.1,3.8,15.9 c0.2,2.8-0.3,9.2-8.7,23.7c-8.5,14.5-21.4,37.7-22.7,37.5c-1.3-0.2-33.3-21.4-49.8-26.7c-16.5-5.2-27.7-6.8-32.9-6.8 c-4.6,0-15.4,2.4-18.3,1.6l-0.5-0.3c1.2-0.9,7.9-5.5,22.6-5.9c16.3-0.3,24.9,5,30.8,7.3s18.1,6.1,23.7,2.6s6-8.5,8.7-10.5 c2.7-2,5.7-0.7,8.5-3.2c2.8-2.5,0.8-4.4,1.6-5.4s4.3,0.3,6.5-2.2c2.2-2.6,0.2-8.7,1.2-9.9c1-1.2,10.4-4.7,10.7-10.4 c0.3-5.7-9.1-11.1-8.8-21.7c0.3-10.6,8.2-19,8.2-48.1s-27.4-80.5-82.9-80.5s-96.6,46-96.6,90c0,24,9.1,46.6,15.4,62.1 c1.9,4.7,3.6,9.3,5.5,13.7c0.1,1.1,0.3,2.2,0.7,3.3c3.1,9.1,5.2,18,5.2,27.2c0,32.5-11.8,50,4.3,85.8c7,15.3,18.3,33.9,31.6,51 c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c1.3,1.7,2.6,3.4,4,5c0.3,0.3,0.5,0.5,0.7,0.8c0.4,0.8,38.3,40.1,60.2,51.8 c43.7,23.4,70.9,24.3,86.2,17.1c0.5-0.2,1-0.5,1.4-0.7c3.3-1.7,6.2-3.8,8.8-6.1c12.2-9.2,23.7-25.5,28.4-51.4 c6-33.1,6-59.8-0.3-64.2s-16.4-9.4-15.5-10.5c0.9-1.1,13.5,5.9,27.7,10.1s22.5,5.9,27.5,8.1s16.2,19.4,25.3,20.5 c9.1,1.1,11-2.4,15-7.6c4.1-5.2,18.6-17.4,26.7-27.5c8.1-10.1,14.8-21.8,15.1-25C437.5,317.8,433.1,313.8,430,314z"
              />
            </svg>
          </div>
          <div className="select-container">
            <SelectInput
              options={fetusPositionCategories.map(option => ({ value: option, label: option }))}
              value={currentCategory || fetusPositionCategories[0]}
              onChange={(value) => setPositionCategory(value)}
            />
            {!!fetusPositionCategoryPositions.filter(position => position.label).length && (
              <SelectInput
                options={fetusPositionCategoryPositions.map(option => ({ value: option.id, label: option.label }))}
                value={currentPosition}
                onChange={(value) => { onFetusPositionChange(value) }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(ExaminationViewFetusPanel);