import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { MeasurementDefaultUnits } from "../../../../config";
import { convertValueToSelectedUnit } from "../../../../unitConverter";
import { measurementLabel } from "../../../../biometryUtils";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";
import Icon from "../../../../atoms/Icon/Icon";
import InlineInput from "../../../../atoms/InlineEditing/InlineInput/InlineInput";

const IdentifierBiometry = ({
  t: __,
  props,
  reportData,
  placeholders,
  onEndEditing,
  reportMode,
  measurementsContext,
}) => {
  // TODO. Likely Un-Comment the below once translated
  // const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase() || "en";
  const currentLanguage = "en";

  const { site, label, "sort-by": sortBy } = props;
  const laterality = props.laterality;

  let columns;
  switch (site) {
    case "ovarian_follicle":
      columns = [
        "follicle_diameter",
        "long_axis",
        "radius",
        "short_axis",
        "volume",
      ];
      break;
    default:
      columns = ["length", "width", "height", "volume"];
      break;
  }

  if (site == null || laterality == null) {
    console.error(
      "<identifier-biometry> Please specify site and laterality attributes"
    );
    return null;
  }

  const slugStart = `identifier_measurement.${site}/${laterality}`;

  const getSortedCollection = () => {
    const c = Object.entries(placeholders || {}).filter(([slug, _]) =>
      slug.startsWith(slugStart)
    );

    const collection = {};
    for (const [slug, data] of c) {
      const splitSlug = slug.split("/");
      const identifier = splitSlug[splitSlug.length - 1];
      collection[identifier] = data;
    }

    return Object.entries(collection).sort(
      (a, b) =>
        a[1]?.measurements?.[sortBy]?.value -
        b[1]?.measurements?.[sortBy]?.value
    );
  };

  const [sortedCollection, setSortedCollection] = useState(
    getSortedCollection()
  );

  useEffect(() => {
    setSortedCollection(getSortedCollection());
  }, [
    JSON.stringify(
      Object.entries(placeholders || {}).filter(([slug, _]) =>
        slug.startsWith(slugStart)
      )
    ),
  ]);

  const headerVisible = sortedCollection.some(([_, { visible }]) => visible);
  const maxId = Math.max(
    Math.max(...sortedCollection.map(([id, _]) => Number(id))),
    0
  );

  const addRow = () => {
    const nextId = maxId + 1;
    const slug = `${slugStart}/${nextId}`;
    const updates = {
      measurement_id: slug,
      fetus: "identifier",
      visible: true,
    };
    onEndEditing(slug, updates);
    setSortedCollection((prev) => [
      ...prev,
      [`${nextId}`, { visible: true, source: "edit", measurements: {} }],
    ]);
  };

  const removeRow = (id) => {
    measurementsContext.removeIdentifierMeasurementRow(
      `${slugStart.replace("identifier_measurement.", "")}/${id}`
    );
    setSortedCollection((prev) => prev.filter(([currId, _]) => id !== currId));
  };

  return (
    <div className="identifier-biometry-table">
      {label && (
        <span
          className={`${
            headerVisible
              ? "is-visible is-printable"
              : "not-visible not-printable"
          }`}
        >
          {label}
        </span>
      )}
      <div
        className={
          "column-heading identifier-biometry-id " +
          `${
            headerVisible
              ? "is-visible is-printable has-printable-value"
              : "not-visible not-printable"
          }`
        }
      >
        {["id", ...columns, "comment"].map((label, index) => {
          switch (label) {
            case "id":
              label = __("report.identifierBiometries.id");
              break;
            case "comment":
              label = __("report.identifierBiometries.comment");
              break;
            default:
              label = measurementLabel(
                label,
                measurementsContext?.labels,
                currentLanguage
              );
              break;
          }
          return <div key={index}>{label}</div>;
        })}
      </div>

      {sortedCollection.map(
        ([id, { measurements, visible, comment, source }]) => {
          const saveChange = (updates) => {
            const slug = `${slugStart}/${id}`;

            updates.measurement_id = slug;
            updates.fetus = "identifier";
            onEndEditing(slug, updates);
          };

          return (
            <div
              key={id}
              className={`identifier-biometry-id ${
                visible ? "is-visible" : "not-visible"
              }`}
            >
              <div className="measurement-list">
                <span>{id}</span>
              </div>
              <ItemMeasurements
                currentLanguage={currentLanguage}
                item={measurements}
                columns={columns}
                saveChange={saveChange}
                measurementsContext={measurementsContext}
              />
              <div className="measurement-list">
                <InlineInput
                  value={comment}
                  format="string"
                  printable={true}
                  onChange={(comment) => saveChange({ comment })}
                />
              </div>
              {visible && (
                <div className="exam-report-editing-options">
                  <div onClick={() => saveChange({ visible: false })}>
                    <Icon name={visible ? "eye" : "eye-off"} />
                  </div>
                  {source === "edit" && id === maxId && (
                    <div onClick={() => removeRow(id)}>
                      <Icon name="trash" />
                    </div>
                  )}
                </div>
              )}
              {!visible && (
                <NotVisibleOverlay
                  onClick={() => saveChange({ visible: true })}
                />
              )}
            </div>
          );
        }
      )}
      {reportMode === "edit" && (
        <div className="identifier-biometry add-row" onClick={addRow}>
          <div className="plus-icon">
            <Icon name="add" />
          </div>
        </div>
      )}
    </div>
  );
};

const ItemMeasurements = ({
  item,
  measurementsContext,
  currentLanguage,
  columns,
  saveChange,
}) => {
  return columns.map((column) => {
    const storedUnit =
      MeasurementDefaultUnits[
        measurementsContext?.labels?.measurement?.[column]?.type
      ];
    const defaultDisplayUnit =
      measurementsContext?.labels?.measurement?.[column]?.units;

    const unit = item?.[column]?.unit || defaultDisplayUnit;
    const value = item?.[column]?.value
      ? convertValueToSelectedUnit(item[column].value, storedUnit, unit)
      : "";

    const decimals = {
      cyst_diameter: 1,
      volume: 2,
      follicle_diameter: 2,
      long_axis: 2,
      radius: 2,
      short_axis: 2,
      length: 1,
      width: 1,
      height: 1,
    };

    const saveChangeValue = (value) => {
      value = convertValueToSelectedUnit(value, unit, storedUnit);
      saveChange({ [column]: { value } });
    };

    return (
      <div className="measurement-list" key={column}>
        <InlineInput
          value={value === "" ? "" : Number(value).toFixed(decimals[column])}
          format="number"
          printable={true}
          onChange={saveChangeValue}
        />
        &nbsp;
        {value === "" ? "" : unit}
      </div>
    );
  });
};
export default withTranslation()(IdentifierBiometry);
