import { useContext, useEffect, useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import { Snack, initialSnackState, snackEvent } from "../../atoms/Snack";
import { AppContext } from "../../context-providers/App";
import { useXMLTemplate } from "../../context-providers/XMLTemplate";
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from "../../context-providers/Examination";
import { MeasurementsContext } from '../../context-providers/Measurements';

import { formatName } from '../../services/examination';
import ResourceApi from '../../services/resource';

import LoaderInline from "../../atoms/LoaderInline/LoaderInline"
import { useXMLDocumentPrinter } from '../../components/XMLDocument';

import ExaminationReportView from './ExaminationReportView';

import { ExamStatus } from '../../config';

const buildMultipartForm = (html, json) => {
  // Build a form with the HTML content as blob 
  const formData = new FormData();
  const html_blob = new Blob([html], { type: "text/html" });
  formData.append("html", html_blob);
  if (json) {
    const json_blob = new Blob([JSON.stringify(json)], { type: "text/html" });
    formData.append("json", json_blob);
  }

  return formData;
}

const ExaminationReport = ({ t: __ }) => {
  const examinationContext = useContext(ExaminationContext);

  const appContext = useContext(AppContext);
  const {
    loaded,
    reportData,
    reportDataOptions,
    /* Legacy will be removed from API */
    setReportData = () => { },
    placeholders,
    loadDynamicReportData
  } = useXMLTemplate();

  const XMLPrinter = useXMLDocumentPrinter();

  const { isFeatureFlagEnabled, siteFlowsConnectors, user } = useAuth();

  const [snack, setSnack] = useState(initialSnackState({}));

  const [uploadingReportAsPDF, setUploadingReportAsPDF] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [base64Images, setBase64Images] = useState([]);

  const [submitDialogIsOpen, setSubmitDialogIsOpen] = useState(false);
  const [reviewReportDialogIsOpen, setReviewReportDialogIsOpen] = useState(false);
  const [stakeholdersDialogIsOpen, setStakeholdersDialogIsOpen] = useState(false);
  const [manageMeasurementsIsOpen, setManageMeasurementsIsOpen] = useState(false);
  const [previewExamReportDialogIsOpen, setPreviewExamReportDialogIsOpen] = useState(false);
  const [editChecklistDialogIsOpen, setEditChecklistDialogIsOpen] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  /* contains null or an Object with information to perform the sign event
   * except the HTML.
   */
  const templateBlueprint = examinationContext.debugTemplate || reportDataOptions?.report_template?.blueprint;
  const examEvents = examinationContext.examination.events;

  const downloadReportAsPDF = async () => {
    setDownloadingPDF(true)
    ResourceApi.getReportPdf(examinationContext.examination.id).then(response => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const date = new Date().toJSON().slice(0, 16).replace(/[T|:]/g, "-");
      const patientName = examinationContext.patient?.name ? formatName(examinationContext.patient.name).fullName : __("patients.anonymous");
      const fileName = `Sonio-${date}-${patientName}.pdf`;
      const a = document.createElement('a');
      let blob = new Blob([response.data]);
      blob = blob.slice(0, blob.size, "application/pdf");
      a.href = window.URL.createObjectURL(blob);
      if (!isMobile) a.setAttribute('download', fileName);
      a.click();
      a.remove();

      setPreviewExamReportDialogIsOpen(false);
    })
      .catch(() => {
        // TODO: handle error
        return
      })
    .finally(() => setDownloadingPDF(false));
  };

  const uploadReportAsPDF = async () => {
    if (uploadingReportAsPDF) return;
    const HTML = await renderReportHtml();

    ResourceApi.sharePdfFromHtmlChrome(buildMultipartForm(HTML)).then(response => {
      snackEvent("show", setSnack, { type: "success", assigns: { state: "uploaded" }, hideAfter: 3000 });
    }).catch(() => {
      snackEvent("show", setSnack, { type: "error", assigns: { state: "error" }, hideAfter: 5000 });
    }).finally(() => setUploadingReportAsPDF(false));
  };

  const renderReportHtml = useCallback(async () => await XMLPrinter({
    base64Images,
    templateBlueprint
  }), [base64Images, templateBlueprint, XMLPrinter]);

  const snackMessage = ({ state }) => {
    switch (state) {
      case "uploaded":
        return "Report uploaded successfully";
      case "error":
        return "Unable to upload your report. (Retry later)";
    }
  };

  /* return a promise that is resolved when the report is signed for real */
  const signReport = (comment) => {
    if (!!placeholders["examination.status"] === ExamStatus.REPORT_SIGNED) {
      return new Promise((resolve, reject) => reject("report already signed"))
    }
    return examinationContext.signReport({
      comment: comment,
      user_timezone: examinationContext.examination.site?.timezone,
    })
  };

  /* return a promise that is resolved when the submition is a success */
  const submitReport = (comment, event_type) => {
    const user_timezone = examinationContext.examination.site.timezone;
    if (event_type == ExamStatus.REPORT_SIGNED) {
      return signReport(comment)
    } else {
      return examinationContext.submitReport({ comment, event_type, user_timezone });
    }
  };

  const afterAssociatingContactPoint = (contactPoint) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data.associated_contact_points.push(contactPoint);
    setReportData(newReportData);
    loadDynamicReportData();
  }

  const afterDeassociatingContactPoint = (contactPointId) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data.associated_contact_points = newReportData.examination_data.associated_contact_points?.filter(cp => cp.id !== contactPointId);
    setReportData(newReportData);
    loadDynamicReportData();
  }

  const getError = () => {
    if (!examinationContext.examination?.patient_id) return __("report.error.anonymousExam");
    if (!examinationContext.examination?.episode) return __("report.error.missingEpisode");
  }

  const createAutomationTemplate = (attrs) => {
    if (examinationContext.examination?.preset) {
      ResourceApi.createAutomationTemplate(
        examinationContext.examination.preset.id,
        attrs
      ).then((resp) => loadDynamicReportData() // Reload the automation templates
      ).catch((error) => console.error(error));
    } else {
      console.error("Cannot create flash report without examination preset.", attrs);
    }
  }

  if (!examEvents)
    return null;

  return (loaded) ? (
    <>
      <Snack snack={snack} setSnack={setSnack}>
        {snackMessage(snack.assigns)}
      </Snack>
      <ExaminationReportView
        isFeatureFlagEnabled={isFeatureFlagEnabled}
        templateBlueprint={templateBlueprint}
        downloadReportAsPDF={downloadReportAsPDF}
        downloadingPDF={downloadingPDF}
        uploadReportAsPDF={siteFlowsConnectors?.upload_report?.length ? uploadReportAsPDF : null}
        uploadingReportAsPDF={uploadingReportAsPDF}
        submitReport={submitReport}
        submitDialogIsOpen={submitDialogIsOpen}
        setSubmitDialogIsOpen={setSubmitDialogIsOpen}
        reviewReportDialogIsOpen={reviewReportDialogIsOpen}
        setReviewReportDialogIsOpen={setReviewReportDialogIsOpen}
        stakeholdersDialogIsOpen={stakeholdersDialogIsOpen}
        setStakeholdersDialogIsOpen={setStakeholdersDialogIsOpen}
        manageMeasurementsIsOpen={manageMeasurementsIsOpen}
        setManageMeasurementsIsOpen={setManageMeasurementsIsOpen}
        previewExamReportDialogIsOpen={previewExamReportDialogIsOpen}
        setPreviewExamReportDialogIsOpen={setPreviewExamReportDialogIsOpen}
        editChecklistDialogIsOpen={editChecklistDialogIsOpen}
        setEditChecklistDialogIsOpen={setEditChecklistDialogIsOpen}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        examStatus={examinationContext.examination.status}
        examEvents={examEvents}
        appContext={appContext}
        renderHTML={renderReportHtml}
        associateContactPoint={(params) => examinationContext.associateContactPoint(params, afterAssociatingContactPoint)}
        deassociateContactPoint={(association_cp_id) => examinationContext.deassociateContactPoint(association_cp_id, afterDeassociatingContactPoint)}
        base64Images={base64Images}
        setBase64Images={setBase64Images}
        createAutomationTemplate={createAutomationTemplate}
      />
    </>
  ) : (
    !!getError() ? <ExaminationReportError error={getError()} /> : <ExaminationReportLoading />
  );
};

export default withTranslation()(ExaminationReport);



const ExaminationReportLoading = ({ }) => {

  return <div className="exam-report-container exam-report-loading">
    <LoaderInline />
  </div>;
}

const ExaminationReportError = ({ error }) => {
  return <div className="exam-report-container exam-report-error">
    {error}
  </div>;
};

