import { withTranslation } from "react-i18next";
import React, { useState, useContext } from "react";
import ResourceApi from "../../services/resource";
import { AppContext } from "../../context-providers/App";
import "./PrintingSharingSettings.css";
import useAnchorScroll from "../../hooks/useAnchorScroll";
import ImageSettingField from "./ImageSettingField";
import { LOADING_IMAGE_URL } from "./constants";
import { getLogoFormData, uploadImage, onError } from "./helpers";

const LogoSettings = ({ t: __ }) => {
  useAnchorScroll();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { site, setSite } = useContext(AppContext);

  const handleImageChange = async (e) => {
    const formData = getLogoFormData(e, (show) => setErrorMessage(show ? __("preferences.printingSharing.logo.uploadTooBig") : false));
    if (formData) {
      await uploadImage(formData, (show) => setErrorMessage(show ? __("preferences.printingSharing.logo.uploadError") : false), setLoading, setSite);
    }
  }

  return (
    <ImageSettingField
      idSlug="logo"
      title={__("preferences.printingSharing.logo")}
      subTitle={__("preferences.printingSharing.uploadInfo")}
      description={__("preferences.printingSharing.logoDescription")}
      uploadButtonText={__("preferences.printingSharing.upload")}
      onChange={handleImageChange}
      onDelete={handleImageChange}
      errorMessage={errorMessage}
    >
      {loading ? <img src={LOADING_IMAGE_URL} alt="Loading" /> : <img src={ResourceApi.getSiteLogo(site?.id)} alt="Logo" onError={onError} />}
    </ImageSettingField>
  )
};

export default withTranslation()(LogoSettings);
