import axios from "axios";

const LookupApi = {
  getChecklistTemplate: (id = "") => axios.get(`/checklist-template/${id}`),
  getExamZone: (id = "") => axios.get(`/exam-zone/${id}`),
  getImagePlane: (id = "") => axios.get(`/instance-view/${id}`),
  listImagePlane: () => axios.get(`/instance-view`),
  // ExamItem
  getExamItem: () => axios.get("exam-item"),
  getExamItemByZone: (exam_zone_id) =>
    axios.get("exam-item", { params: { exam_zone_id } }),
  getExamItemByView: (instanceViewId) =>
    axios.get("exam-item", { params: { instanceViewId } }),
  getExamItemByRiskFactor: (riskFactorIds) =>
    axios.get("exam-item", {
      params: { risk_factor_ids: riskFactorIds.join(",") },
    }),
  // Medical history
  getMedicalHistoryItem: () => axios.get("medical-history-item"),
  // Medications / Teratogenic risks / CRAT
  getMedicationsBySearchKey: (searchKey) =>
    axios.get("teratogen-substance", { params: { search: searchKey } }),
  getMedicationsByIds: (ids) =>
    axios.get("teratogen-substance", { params: { ids: ids.join(",") } }),
  getMedicationsByRiskFactorIds: (riskFactorIds) =>
    axios.get("teratogen-substance", {
      params: { risk_factor_ids: riskFactorIds.join(",") },
    }),
  getMalformation: () => axios.get("malformation"), // TODO: we can remove synonyms from the response
  getMalformationBySearchKey: (searchKey, excludeIds = [], lang = "fr") =>
    axios.get("malformation", {
      params: { search_key: searchKey, exclude: excludeIds.join(","), lang },
    }),
  getSyndrome: () => axios.get("syndrome"),
  getGenesForSyndrome: (syndromeId) => axios.get(`/syndrome/${syndromeId}/genes`).then(r => r.data.data),
  // Fetus Positions
  getFetusPositions: () => axios.get(`/fetus-positions`),
  version: () => axios.get("version", { params: { type: "webapp" } }),
  // Structures
  getStructures: () => axios.get(`/structure`),
  // Quality Criteria
  getQualityCriteria: (examinationId) => axios.get(`/examination/${examinationId}/config-quality-criteria`),
  // Certification ID types list (by country)
  getCertificationIdTypes: (country_id) => axios.get(`/lookup/certification_id_type/${country_id}`),
  // Semiology sign
  getSemiologySign: () => axios.get("semiology-sign"),
  getChecklistItemBySearchKey: (searchKey, lang = "fr", examinationId) =>
    axios.get("checklist-item", {
      params: { search_key: searchKey, lang, examination_id: examinationId },
    }),
  getSRExtraction: (examinationId) =>
    axios.get(`/examination/${examinationId}/sr-dicom-extraction`),
};

export default LookupApi;
