import { useState, useRef, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import { ExaminationContext } from '../../context-providers/Examination';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import useAuth from "../../context-providers/Auth";

import { convertTimeZone, formatYYYYMMDDDate, compareDates, generateRandomString } from "../../utils";

import "./ExaminationReport.css";

import Button from '../../atoms/Button/Button';
import ViewExamEventsDialog from './ViewExamEventsDialog';
import ViewExamStakeholdersDialog from './ViewExamStakeholdersDialog';
import PreviewExamReportDialog from './PreviewExamReportDialog';
import Icon from '../../atoms/Icon/Icon';
import { ExamStatus } from '../../config';
import ResourceApi from '../../services/resource';
import MeasurementsDialog from './MeasurementsDialog';
import EditChecklistItems from './editors/EditChecklistItems';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import TextInput from '../../atoms/TextInput/TextInput';
import ArrangeOnTop from '../../atoms/ArrangeOnTop/ArrangeOnTop';

import { XMLDocument } from '../../components/XMLDocument';

const ExaminationReportView = ({
  /* TODO remove and use the i18n */
  t: __,
  /* TODO remove and use the context */
  isFeatureFlagEnabled,
  templateBlueprint,
  downloadReportAsPDF,
  downloadingPDF,
  setSubmitDialogIsOpen,
  submitDialogIsOpen,
  reviewReportDialogIsOpen,
  setReviewReportDialogIsOpen,
  editChecklistDialogIsOpen,
  setEditChecklistDialogIsOpen,
  setStakeholdersDialogIsOpen,
  previewExamReportDialogIsOpen,
  setPreviewExamReportDialogIsOpen,
  manageMeasurementsIsOpen,
  setManageMeasurementsIsOpen,
  commentValue,
  setCommentValue,
  submitReport,
  examStatus,
  examEvents,
  appContext,
  associateContactPoint,
  deassociateContactPoint,
  renderHTML,
  base64Images,
  setBase64Images,
  createAutomationTemplate,
}) => {
  const examinationContext = useContext(ExaminationContext);

  const { siteFlowsConnectors } = useAuth();
  const {
    reportDataOptions,
    placeholders,
    generateAutomationTemplate,
    componentChecklistAssoc,
    applyAutomationTemplate,
    automationTemplateFieldsVisible,
    setAutomationTemplateFieldsVisible,
  } = useXMLTemplate();

  // TODO require all checklist items from report data or examinationContext ???
  const checklistItemsToDisplay = Object.entries(placeholders).filter(([key, value]) => key.startsWith("checklist.item.") && Array.isArray(value) && value.some(valuePerFetus => valuePerFetus.active)).map(item => item[1]);

  const [requiredAndInvalid, setRequiredAndInvalid] = useState(new Set());
  const [showErrors, setShowErrors] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [flashReportName, setFlashReportName] = useState("");
  const reportContentRef = useRef(null);

  /* TODO move this into the XMLDocument context with BI event hook */
  const onCloseWindow = () => {
    ResourceApi.createExaminationEvent(examinationContext.examination.id, "report_closed")
  }

  useEffect(() => {
    window.addEventListener("beforeunload", onCloseWindow);
    ResourceApi.createExaminationEvent(examinationContext.examination.id, "report_opened")
    ResourceApi.createBIEvent({
      event_type: "report_opened",
      examination_id: examinationContext.examination.id,
      report_id: reportDataOptions.report_id
    })
    return () => {
      onCloseWindow();
      window.removeEventListener("beforeunload", onCloseWindow);
      ResourceApi.createExaminationEvent(examinationContext.examination.id, "report_closed")
      ResourceApi.createBIEvent({
        event_type: "report_closed",
        examination_id: examinationContext.examination.id,
        report_id: reportDataOptions.report_id
      })
    }
  }, []);

  const examStatusLabel = () => {
    const ee = examEvents.filter(ee => ee.exam_status === examStatus).sort((a, b) => b.id - a.id)[0];
    const date = ee?.inserted_at + 'Z';

    const tz_date = formatYYYYMMDDDate(convertTimeZone(date, examinationContext.examination?.site?.timezone) || "", appContext.preferences.date_format);
    const tz_time = convertTimeZone(date, examinationContext.examination?.site?.timezone)?.substr(11, 5);

    const readyForReview = examinationContext.examination.status === ExamStatus.READY_FOR_REVIEW;
    const submittedOn = examinationContext.examination.events.sort((a, b) => a.inserted_at < b.inserted_at ? 1 : -1).find(event => event.event_type === "status_update" && event.exam_status === ExamStatus.READY_FOR_REVIEW)?.inserted_at;
    const lastDicomTime = examinationContext.examination.events.sort((a, b) => a.inserted_at < b.inserted_at ? 1 : -1).find(event => event.event_type === "dicom")?.inserted_at;

    return <div className="exam-report-status">
      {__("examinationReview.status." + examStatus)}.&nbsp;
      {tz_date && tz_time && __("examinationReview.status." + examStatus + ".meta", { date: tz_date, time: tz_time, name: ee?.entity?.title })}
      {!examinationContext.canEdit &&
        <div>
          <Icon name="lock_closed" /> {__("examinationReview.examIsLocked")}
          {examinationContext.canUnfreeze && <>
            {__(":")} <Button label={__("examinationReview.editExam")} variant="link" size="small" onClick={() => examinationContext.reopenExamination()} />
          </>}
        </div>
      }
      {
        readyForReview
        && compareDates(submittedOn, lastDicomTime) === -1
        && (
          <div className="exam-completed-status-warning">
            <Icon name="warning" />
            {__("examinationReview.dicomReceivedAfterSubmission")}
          </div>
        )
      }
    </div>;
  };

  // Handle missing required fields
  const scrollToFirstError = () => {
    const firstErrorElem = reportContentRef.current.getElementsByClassName('required-error')[0];
    if (firstErrorElem)
      firstErrorElem.scrollIntoView({ behavior: "smooth", block: 'nearest' }); // 'nearest' needed because scrolling to the end of the report display the notification panel
  };

  const onClickSubmit = (newStatus) => {
    setShowErrors(false);
    setSubmitClicked(true);
    const isCodingProvided = (examinationContext.examination?.procedures_codes?.length != 0) && (examinationContext.examination?.diagnoses_codes?.length != 0)
    const isCodingCorrectlyProvided = (examinationContext.examination?.preset?.is_coding_mandatory && isCodingProvided) || !examinationContext.examination?.preset?.is_coding_mandatory || !siteFlowsConnectors?.coding

    /* Keep this to understand why we can't sign sometimes */
    if (!isCodingCorrectlyProvided)
      console.warn("Coding is not correctly filled in. Please fill it before submitting")
    if (requiredAndInvalid.size !== 0)
      console.warn("Some mandatory fields are not correctly provided.", requiredAndInvalid)
    if (requiredAndInvalid.size === 0 && isCodingCorrectlyProvided) {
      setSubmitDialogIsOpen(newStatus);
      return;
    }
    setShowErrors(true);
  };

  useEffect(() => {
    if (showErrors && submitClicked) {
      setSubmitClicked(false);
      scrollToFirstError();
    }
  }, [showErrors, submitClicked]);

  const getAutomationTemplateOptions = () => {
    if (automationTemplateFieldsVisible) return [];

    let options = reportDataOptions.automation_templates?.map(t => ({ label: t.name, value: t.slug })) || [];

    if (isFeatureFlagEnabled("sonio.create_quick_reports")) {
      if (reportDataOptions.automation_templates?.length > 0) options.push({ type: "separator" });

      options.push({ label: "Add new", value: "add-new-flash-report" });
    }

    return options;
  }

  const closeNewFlashReportMode = () => {
    setFlashReportName("");
    setAutomationTemplateFieldsVisible(false);
  }

  const handleSaveFlashReport = () => {
    generateAutomationTemplate().then(template => {
      createAutomationTemplate({
        slug: `${flashReportName.toLowerCase().replaceAll(" ", "-")}-${generateRandomString()}`,
        name: flashReportName,
        template: template
      })
    });

    closeNewFlashReportMode();
  };

  return <div className={`exam-report-wrapper ${examinationContext.canEdit ? '' : 'frozen'}`}>
    <div ref={reportContentRef} className="exam-report-content">
      {!examinationContext.canEdit && <div className="exam-report-frozen-warning" onClick={() => examinationContext.reopenExamination()}><div /></div>}
      <XMLDocument
        templateBlueprint={templateBlueprint}
        page="report"
        showErrors={showErrors}
        submitClicked={submitClicked}
        setEditChecklistDialogIsOpen={setEditChecklistDialogIsOpen}
        reportMode="edit"
        setManageMeasurementsIsOpen={setManageMeasurementsIsOpen}
        base64Images={base64Images}
        setBase64Images={setBase64Images}
        requiredAndInvalid={requiredAndInvalid}
        setRequiredAndInvalid={setRequiredAndInvalid}
      />
    </div>
    <div className="exam-report-options">
      <div className="exam-report-options-column">
        <Button variant="outline" onClick={() => setReviewReportDialogIsOpen(true)} icon="comment" label={__("examinationReview.dialog.activity")} />
        <Button variant="outline" onClick={() => setManageMeasurementsIsOpen(true)} onClickDisabled={examinationContext.reopenExamination} icon="settings-alt" label={__("examinationReview.dialog.measurements")} disabled={!examinationContext.canEdit} />
        <Button variant="outline" onClick={() => setEditChecklistDialogIsOpen(true)} onClickDisabled={() => examinationContext.reopenExamination()} icon="done" label={__("examinationReview.dialog.checklist")} disabled={!examinationContext.canEdit} />
        {isFeatureFlagEnabled("sonio.quick_reports") && (
          <div>
            <SelectInput
              options={getAutomationTemplateOptions()}
              onChange={(slug) => slug === "add-new-flash-report"
                ? setAutomationTemplateFieldsVisible(true)
                : applyAutomationTemplate(slug)
              }
              placeholder={{ icon: "flash", label: "" }}
              showDropDown={false}
              variant="outline"
              padding="big"
              disabled={!examinationContext.canEdit || automationTemplateFieldsVisible}
            />
            {automationTemplateFieldsVisible && (
              <ArrangeOnTop variant="balloon">
                <div className="exam-report-options_create-new-flash-report">
                  <TextInput placeholder="Flash Report Name" value={flashReportName} onChange={setFlashReportName} />
                  <Button size="input" icon="flash" label="Save" onClick={handleSaveFlashReport} />
                  <Button size="input" variant="outline" label="Cancel" onClick={() => setAutomationTemplateFieldsVisible(false)} />
                </div>
              </ArrangeOnTop>
            )}
          </div>
        )}

        {examStatusLabel()}
      </div>
      <div className="exam-report-options-column">
        <Button variant="outline" onClick={() => setPreviewExamReportDialogIsOpen(true)} icon="download" label={__("examinationReview.dialog.previewDownloadPdf")} />
        {examinationContext.canSubmit && !examinationContext.canSign && <Button onClick={() => onClickSubmit(ExamStatus.READY_FOR_REVIEW)} icon="edit" label={__("common.submit")} />}
        {examinationContext.canSign && <Button onClick={() => onClickSubmit(ExamStatus.REPORT_SIGNED)} icon="edit" label={__("examinationReview.dialog.signReport")} />}
      </div>
    </div>
    {submitDialogIsOpen &&
      <ViewExamStakeholdersDialog
        submitDialogIsOpen={submitDialogIsOpen}
        setSubmitDialogIsOpen={setSubmitDialogIsOpen}
        setStakeholdersDialogIsOpen={setStakeholdersDialogIsOpen}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        examEvents={examEvents}
        submitReport={submitReport}
        examination={examinationContext.examination}
        associateEntity={examinationContext.associateEntity}
        deassociateEntity={examinationContext.deassociateEntity}
        associateContactPoint={associateContactPoint}
        deassociateContactPoint={deassociateContactPoint}
      />
    }

    {examinationContext.canEdit && manageMeasurementsIsOpen &&
      <MeasurementsDialog
        manageMeasurementsIsOpen={manageMeasurementsIsOpen}
        setManageMeasurementsIsOpen={setManageMeasurementsIsOpen}
      />
    }

    {reviewReportDialogIsOpen &&
      <ViewExamEventsDialog
        setReviewReportDialogIsOpen={setReviewReportDialogIsOpen}
        commentValue={commentValue}
        setCommentValue={setCommentValue}
        examEvents={examEvents}
        submitReport={submitReport}
        examination={examinationContext.examination}
        updateExamination={examinationContext.updateExamination}
      />
    }

    {previewExamReportDialogIsOpen &&
      <PreviewExamReportDialog
        setPreviewExamReportDialogIsOpen={setPreviewExamReportDialogIsOpen}
        renderHTML={renderHTML}
        downloadReportAsPDF={downloadReportAsPDF}
        downloadingPDF={downloadingPDF}
      />
    }

    {examinationContext.canEdit && editChecklistDialogIsOpen && (
      <EditChecklistItems
        fetus={editChecklistDialogIsOpen?.fetus ?? 1}
        fetuses={[{ id: null }].concat(examinationContext.examination.fetuses)}
        groups={reportDataOptions.checklist_item_groups}
        items={checklistItemsToDisplay.filter(item => {
          const slug = item[editChecklistDialogIsOpen?.fetus ?? 1]?.slug;
          return editChecklistDialogIsOpen.allowedSlugs
            ? editChecklistDialogIsOpen.allowedSlugs.includes(slug)
            : Object.values(componentChecklistAssoc).flat().includes(slug);
        })}
        initialGroup={editChecklistDialogIsOpen?.slug}
        componentChecklistAssoc={editChecklistDialogIsOpen.componentUID ? componentChecklistAssoc[editChecklistDialogIsOpen.componentUID] : false}
        componentUID={Object.keys(componentChecklistAssoc).length === 1 ? Object.keys(componentChecklistAssoc)[0] : editChecklistDialogIsOpen.componentUID}
        close={() => setEditChecklistDialogIsOpen(false)}
      />
    )}
  </div >;
};

export default withTranslation()(ExaminationReportView);
