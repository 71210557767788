import ResourceApi from "../../services/resource";
import { HEADER_IMAGE_KEY, LOGO_IMAGE_KEY, MAX_SIZE_IN_BYTES, FALLBACK_IMAGE_URL } from "./constants";

const getFormData = (e, key, setErrorMessage) => {
    const files = e?.target?.files ?? [];
    setErrorMessage(false)
    const formData = new FormData();
    if (files.length > 0) {
        const newFile = files[0];
        if (newFile.size <= MAX_SIZE_IN_BYTES) {
            formData.append(key, newFile);
        } else {
            setErrorMessage(true)
            return undefined;
        }
    } else {
        formData.append(key, "");
    }
    e.target.value = ''
    return formData;
}

export const uploadImage = async (formData, setErrorMessage, setLoading, setSite) => {
    setErrorMessage(false);
    setLoading(true);
    try {
        const res = await ResourceApi.uploadBrandImage(formData);
        if (res?.data?.error == null) {
            setSite(res.data.data);
        } else {
            setErrorMessage(true)
        }
    } catch (e) {
        setErrorMessage(true)
    } finally {
        setLoading(false);
    }
}

export const getHeaderFormData = (e, setErrorMessage) => getFormData(e, HEADER_IMAGE_KEY, setErrorMessage);
export const getLogoFormData = (e, setErrorMessage) => getFormData(e, LOGO_IMAGE_KEY, setErrorMessage);

export const onError = (e) => {
    e.target.src = FALLBACK_IMAGE_URL;
}
