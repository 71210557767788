import { useEffect, useRef, useState, useCallback } from "react";
import Icon from "../../atoms/Icon/Icon";
import "./SectionsNavBar.css";

/**
 * <SectionsNavBar
 *  options = [{icon, title, description, target}, ...]
 *  actions = [{icon, title, description, onClick}, ...]
 *  actionsAfter = [{icon, title, description, onClick}, ...]
 * />
 */

function target(option) {
  if (option.targetClass) return document.querySelector(option.targetClass);
  if (option.target) return option.target;
  return false;
}

const SectionsNavBar = ({ onChangeCurrentOption = null, options = [], actions = [], actionsAfter = [], disableOptions = false, disableActions = false }) => {
  const [markerStyle, setMarkerStyle] = useState({ top: '0' });
  const [navMenuExpanded, setNavMenuExpanded] = useState(false);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(false);
  const [navContainer, setNavContainer] = useState(null);

  const optionsUid = options.map(option => option.targetClass).join(' ');

  const setChapterAsCurrent = useCallback((optionIndex, navContainer) => {
    setCurrentOptionIndex((currentOptionIndex) => {
      if (currentOptionIndex === optionIndex) return currentOptionIndex;

      const navElement = navContainer.querySelector(`li.option-${optionIndex}`)
      if (navElement) {
        typeof onChangeCurrentOption === 'function' && onChangeCurrentOption(options[navElement.dataset.id]);
        setMarkerStyle((markerStyle) => ({ ...markerStyle, top: navElement.offsetTop + 'px' }));
      }
      return optionIndex;
    });
  }, [onChangeCurrentOption, optionsUid, setMarkerStyle]);

  useEffect(() => {
    if (!options.length) return false;
    if (!navContainer) return false;

    const intersectionObserverCallback = (entries, _observer) => {
      const lastIntersecting = (entries.reverse()).find(e => e.isIntersecting);
      if (lastIntersecting) {
        const boundingRect = lastIntersecting.boundingClientRect
        const intersectionRect = lastIntersecting.intersectionRect
        if (boundingRect.top <= intersectionRect.top) {
          const optionIndex = options.findIndex(option => target(option) === lastIntersecting.target);
          if (optionIndex === false) return false;
          setChapterAsCurrent(optionIndex, navContainer);
        }
      }
    }

    const observer = new IntersectionObserver(intersectionObserverCallback, {
      rootMargin: '0px',
      threshold: 0.2,
    })

    const targets = options.map(option => target(option));
    for (const target of targets) {
      if (target) observer.observe(target);
    }

    return () => {
      for (const target of targets) {
        if (target) observer.unobserve(target);
      }
    }
  }, [optionsUid, setChapterAsCurrent, navContainer]);

  /**
   * navigation menu
   */
  const expandNavMenu = useCallback((e) => {
    if (disableOptions || actions.some(action => action.selected) || actionsAfter.some(action => action.selected)) return false;

    let nav = e?.target.querySelector('ul') || e?.target.closest('ul');
    if (nav) {
      nav.classList.add('expanded');
      nav.style.width = `${nav.scrollWidth}px`;
    }
    setNavMenuExpanded(true);
  }, [disableOptions, actions, actionsAfter]);

  const shrinkNavMenu = useCallback((e) => {
    const nav = e?.target.querySelector('ul') || e?.target.closest('ul');
    if (nav) {
      nav.classList.remove('expanded')
      nav.style.width = ''
    }
    setNavMenuExpanded(false);
  }, []);

  /*
   * shrink the nav menu after 2 seconds
   */
  useEffect(() => {
    if (!navMenuExpanded) return false;
    const navbarTimer = setTimeout(shrinkNavMenu, 2000);
    return () => {
      clearTimeout(navbarTimer);
    }
  }, [navMenuExpanded]);

  const goToChapter = (navElement, target) => {
    if (actions.some(action => action.selected)) {
      actions.find(action => action.selected)?.onClick();
    } else if (actionsAfter.some(action => action.selected)) {
      actionsAfter.find(action => action.selected)?.onClick();
    }

    // scroll to the target item
    let scrollableParent = false;
    for (let parent = target.parentNode; parent && !scrollableParent; parent = parent.parentNode) {
      if (parent.scrollHeight > parent.clientHeight) {
        scrollableParent = parent;
        break;
      }
    }

    if (scrollableParent) {
      scrollableParent.scrollTo({
        top: target.offsetTop,
        // smooth make the nav bar menu scroll weirdly
        // we should implement a smooth scroll going from one option to another in constant time
        behavior: 'smooth',
      });
    }
  }

  return (
    <div className={`sections-nav-bar-container ${(actions.some(action => action.selected) || actionsAfter.some(action => action.selected) || disableOptions) ? 'action-selected' : ''} ${disableActions ? 'disable-actions' : ''}`}>
      {!!actions.length && (
        <div
          className="sections-nav-bar sections-nav-bar-actions"
        >
          <ul>
            {actions.map((action, i) => {
              return <li key={i} className={`action-${i} ${action.selected ? 'selected' : ''} ${action.unusual ? 'unusual' : ''} ${action.disabled ? 'disabled' : ''}`} onClick={(_e) => action.onClick()}>
                <div className="icon-wrapper">
                  <Icon name={action.icon} title={action.title} />
                </div>
                {!!action.counter && (<div className="counter">{action.counter}</div>)}
              </li>
            })}
          </ul>
        </div>
      )}
      {!!options.length && (
        <div
          className="sections-nav-bar sections-nav-bar-options"
          onMouseOver={expandNavMenu}
          onMouseOut={shrinkNavMenu}
          ref={setNavContainer}
        >
          <div className="sections-nav-bar-options-corner-top"></div>
          <div className="sections-nav-bar-options-corner-bottom"></div>
          <ul>
            <li className="marker" style={markerStyle}></li>
            {options.map((option, i) => {
              return <li key={i} className={`option-${i} ${option.disabled ? 'disabled' : ''} ${i == currentOptionIndex ? 'current' : ''}`} data-id={i} onClick={(e) => goToChapter(e.currentTarget, target(option))}>
                <div className="icon-wrapper">
                  <Icon name={option.icon} />
                </div>
                <div>
                  <b>{option.title}</b><br />
                  {!!option.description && (option.description)}
                </div>
                {!!option.counter && (<div className="counter">{option.counter}</div>)}
                {!!option.alert && (
                  <span className="risk-factor">
                    <Icon name={option.alert} />
                  </span>
                )}
              </li>
            })}
          </ul>
        </div>
      )}
      {!!actionsAfter.length && (
        <div
          className="sections-nav-bar sections-nav-bar-actions"
        >
          <ul>
            {actionsAfter.map((action, i) => {
              return <li key={i} className={`action-${i} ${action.selected ? 'selected' : ''} ${action.unusual ? 'unusual' : ''} ${action.disabled ? 'disabled' : ''}`} onClick={(e) => action.onClick()}>
                <div className="icon-wrapper">
                  <Icon name={action.icon} title={action.title} />
                </div>
                {!!action.counter && (<div className="counter">{action.counter}</div>)}
              </li>
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SectionsNavBar;
