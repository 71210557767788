import { useContext, useState } from 'react';
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import PatientName from "../../components/PatientName/PatientName";
import Button from '../../atoms/Button/Button';
import { WindowContext } from '../../context-providers/Window';
import { ExaminationContext } from '../../context-providers/Examination';
import "./ExaminationCustomMedicalHistoryView.css";

import { XMLDocument } from '../../components/XMLDocument';


const ExaminationCustomMedicalHistoryView = ({ t: __, isFeatureFlagEnabled, reportData, placeholders, setPlaceholders, getPlaceholderWithProps, appContext, checkCondition, onEndEditing, onEndEditingDating, editingFieldId, startEditingField, startEditing, setAssignedGa, revertAssignedGa, updateEpisode }) => {
  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);
  const templateBlueprint = examinationContext.debugTemplate || reportData?.medical_history_template?.blueprint;

  const { examId } = useParams();
  const history = useHistory();

  const backToExamination = (url = false) => {
    // save modifications before leaving (avoid waiting examinationContext timeout)
    examinationContext.updateExamination({ ...examinationContext.examination });
    examinationContext.updateEpisode({ ...examinationContext.episode });

    if (url === false) url = `/exam/${examId}`;

    if (url.match(new RegExp(`^\/exam\/${Number(examId)}$`)) && isFeatureFlagEnabled("soniopedia")) url += "/dx";

    history.push(url);
  }

  const backToDashboard = () => {
    history.push("/");
  }

  return (
    <div className="custom-medical-history-container">
      {!!templateBlueprint && (
        <>
          <div className="custom-medical-history-header">
            <PatientName patient={examinationContext.patient} episode={examinationContext.episode} examinationDate={examinationContext.examination.examination_date} />
          </div>
          <XMLDocument
            templateBlueprint={templateBlueprint}
            page="medical-history"
            reportMode="edit"
          />
        </>
      )}

      {!windowContext.isDetached && (
        <div className="examination-medical-history-cta-bar">
          {appContext.referer.match(new RegExp(`^/exam/${Number(examId)}/dx$`)) ? (
            <Button label={__("examination.backToExamination")} iconAfter="right-big" onClick={() => backToExamination(appContext.referer)} />
          ) : appContext.referer.match(new RegExp(`^/exam/${Number(examId)}$`)) ? (
            <Button label={__("examination.backToExamination")} iconAfter="right-big" onClick={() => backToExamination(appContext.referer)} />
          ) : examinationContext.examination.status === "draft" ? (
            <>
              <Button label={__("examination.backToTheDashboard")} icon="home" onClick={() => backToDashboard()} />
              <Button label={__("examination.startTheExamination")} variant="outline" iconAfter="right-big" onClick={() => backToExamination()} />
            </>
          ) : (
            <Button label={examinationContext.examination.status !== "draft" ? __("examination.backToExamination") : __("examination.startTheExamination")} iconAfter="right-big" onClick={() => backToExamination()} />
          )}
        </div>
      )}
    </div>
  )
}

export default withTranslation()(ExaminationCustomMedicalHistoryView)
