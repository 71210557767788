/* React */
import { useCallback, useEffect, useState, useReducer, useContext, useMemo } from "react";
import { withTranslation } from 'react-i18next';

/* Contexts */
import { AppContext } from "../App";
import { ExaminationContext } from "../Examination";
import { NotificationContext } from "../Notification";
import { MeasurementsContext } from "../Measurements";
import useAuth from "../Auth";
import { SocketContext } from "../Socket";


/* Services */
import ResourceApi from '../../services/resource';
import evalTemplate from "../../services/automation-template";
import { computeBMI, formatName, getNiceGestionalAgeFromDays, createFullName } from '../../services/examination';

/* Utils & Components */
import { deepMerge, getPatientAge, isNullOrUndefined, timeFormatter, convertTimeZone, replaceAllKeys } from '../../utils';
import { placeholderIdFromProps } from '../../components/XMLDocument/utils';

import EditBiometry from '../../pages/ExaminationReport/editors/EditBiometry';
import Icon from "../../atoms/Icon/Icon";

// TODO dependencies must be managed
// view(reportData, measurementsContext?.measurementData || {}) => 

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const templateIdToMedicalHistoryId = (id) => {
  if (id.startsWith('custom.'))
    return templateIdToMedicalHistoryId(id.slice('custom.'.length));
  if (id.startsWith('medical-history.'))
    return id.slice('medical-history.'.length);
  if (id.startsWith('patient.'))
    return id;
  if (id.startsWith('episode.'))
    return id;
  /* Not a medical history item */
  return false;
};

const medicalHistoryItem = (id, medicalHistoryItems) => {
  const idMH = templateIdToMedicalHistoryId(id)
  return medicalHistoryItems[idMH] || { text_id: idMH };
};

const itemFormatFromMHType = (type) => {
  switch (type) {
    case "multiselect": return "multiple";
    case "buttongroup":
    case "text":
    case "select":
      return "string";
    case "date":
      return "date";
    case "number":
      return "number";
    default:
      return "string";
  }
}

const getMHOptionLabel = (item, value) => {
  if (!item.options?.length) return value;
  if (Array.isArray(value)) return value.map(value => item.options.find(option => option.value === value)?.label[currentLanguage]);
  return item.options.find(option => option.value === value)?.label[currentLanguage];
}

const updateChecklistItemStatus = (items, item) => {
  const index = items.findIndex(({ id, examination_fetus_id }) => item.id == id && examination_fetus_id == item.examination_fetus_id)

  if (index !== -1) {
    items[index] = item
    return items
  }
  return items.concat(item)
};

/* State reducer */

const reportDataReducer = ({ loadeds, state, incr }, { event, data }) => {
  switch (event) {
    case "reset":
      return { incr: incr + 1, loadeds: { dynamic: false, measurements: false }, state: {} }
    case "user_edits":
      return {
        incr: incr + 1, loadeds, state: {
          ...state,
          report: deepMerge(state.report, { report: { user_edits: data } })
        }
      }

    case "checklist_items_with_statuses":
      return {
        incr: incr + 1, loadeds, state: {
          ...state,
          checklist_items_with_statuses: data
        }
      }

    case "frozen":
      return { incr: incr + 1, loadeds, state: { ...state, ...data }, loadeds: { dynamic: true, measurements: true } };
    case "socket":
    case "backup":
    case "internal":
      return { incr: incr + 1, loadeds, state: { ...state, ...data } };
    case "dynamic":
      return { incr: incr + 1, loadeds: { ...loadeds, dynamic: true }, state: { ...state, ...data } };
    case "static":
      return { incr: incr + 1, loadeds: { ...loadeds, static: true }, state: { ...state, ...data } };
    case "measurements":
      return { incr: incr + 1, loadeds: { ...loadeds, measurements: true }, state: { ...state, ...data } };
  }
};

const placeholdersDefinitionReducer = (state, { event, data }) => {
  switch (event) {
    case "reset":
      return {}
    case "add":
      return { ...state, ...data }
    case "remove":
      const { [data]: _, ...rest } = state;
      return rest;
  }
};

const addRequiredPlaceholder = (state, { id, ids }) => {
  const previouslyRequiredPlaceholders = state[id] || [];
  const newRequiredPlaceholders = ids.filter(id => !previouslyRequiredPlaceholders.includes(id));
  const allRequiredPlaceholders = [...previouslyRequiredPlaceholders, ...newRequiredPlaceholders].sort();
  return { ...state, [id]: allRequiredPlaceholders };
}

const requiredPlaceholdersReducer = (state, { event, data }) => {
  switch (event) {
    case "add":
      return addRequiredPlaceholder(state, data);
  }
}

/*
 * This function walk through reportData tree to find if there is any path 
 * associated with the provided ID leading to a value in reportData.
 *
 * Walking steps:
 *  - Key is splitted on `.` character
 *  - For each stem of the key
 *    - if the reportData object contains the key, the exploration continues on the found object
 *    - if the reportData object does not contain the key, the exploration continues with the key accumulated
 *
 *
 * ## Example
 *
 *  - `patient.id` will return `reportData.patient.id`
 *  - `dating_methods.ga.ac.hadlock1984` will return `reportData.dating_methods.["ga.ac.hadlock1984"]`
 *
 * @param {string} id - The ID of the field to find in reportData
 * @returns {any} - The value found in reportData or undefined
 */
const extractFromReportDataById = (id, reportData) => {
  if (id.startsWith("custom.")) return;
  const { prevKeys, data } = id
    .split(".")
    .reduce(({ prevKeys, data }, key) => {
      const appliedKey = prevKeys.concat(key).join(".");
      if (data === undefined) return { prevKeys: [], data: undefined };
      if (typeof data?.hasOwnProperty !== "function") return { prevKeys: [], data: undefined };
      if (data.hasOwnProperty(appliedKey)) return { prevKeys: [], data: data[appliedKey] };
      return { prevKeys: [...prevKeys, key], data: data };
    }, { prevKeys: [], data: reportData });

  /* We matched on a leaf */
  if (prevKeys.length === 0) return data;
  return undefined;
};

const doExtractFromMedicalHistoryById = (id, medicalHistory) => {
  if (id.startsWith("medical-history.")) return medicalHistory[id.slice("medical-history.".length)];
  if (id.startsWith("patient.")) return medicalHistory[id];
  if (id.startsWith("episode.")) return medicalHistory[id];

  return undefined;
}

const extractFromMedicalHistoryById = (id, medicalHistory) => {
  if (id.startsWith("custom.")) return doExtractFromMedicalHistoryById(id.slice("custom.".length), medicalHistory);
  return doExtractFromMedicalHistoryById(id, medicalHistory);
};

const calculatePlaceholder = (id, { placeholdersDefinition, reportData, dynamicDropdowns, measurementData }) => {
  const fromReportData = extractFromReportDataById(id, reportData);
  const fromMedicalHistory = { ...extractFromMedicalHistoryById(id, reportData?.medical_history || {}) };
  const reportImplementedValue = fromReportData !== undefined ? { value: fromReportData } : {};
  const implementedValue = { ...fromMedicalHistory, ...reportImplementedValue };

  const placeholderFromDef = placeholdersDefinition[id] ? placeholdersDefinition[id].view(reportData, measurementData || {}) : implementedValue;
  if (!placeholdersDefinition[id] && !!placeholderFromDef && !id.startsWith("measurement.") && !id.startsWith("custom.") && !id.startsWith("ga."))
    console.warn(`placeholder ${id} is used but not defined in the placeholders definitions`)
  if (placeholderFromDef?.original_id)
    return calculatePlaceholder(placeholderFromDef?.original_id, { placeholdersDefinition, reportData, dynamicDropdowns, measurementData });

  const isArray = Array.isArray(placeholderFromDef);
  const placeholder = isArray ? placeholderFromDef : [placeholderFromDef];
  const user_edits = reportData?.report?.user_edits?.[id] || {};
  // user edits follow a particular rule for fetus.sex.
  // This rule is implemented in the placeholder definition of it.
  const apply_user_edits = (id !== "fetus.sex")

  let dynamicDropdownPlaceholder = {};
  if (dynamicDropdowns[id]) {
    dynamicDropdownPlaceholder = {
      tree: normalizeDynamicDropDownTree(dynamicDropdowns[id]?.tree || []),
      isDynamic: true,
      format: dynamicDropdowns[id].multiple ? "multiple" : "string"
    }
  }

  const output = placeholder.map(p => ({
    ...implementedValue,
    ...(p || {}),
    ...dynamicDropdownPlaceholder,
    ...(apply_user_edits ? user_edits : {}),
    data: id,
  }));

  return (isArray || id.startsWith("measurement.")) ? output : output[0];
};

const refreshPlaceholders = (placeholders, data) => {
  console.log(`Refreshing ${Object.keys(placeholders).length} placeholders`)
  return Object.keys(placeholders).reduce((acc, key) => {
    acc[key] = calculatePlaceholder(key, data);
    return acc;
  }, {})
};


const ensurePlaceholdersLoaded = (placeholders, { ids, resolve, ...data }) => {
  const newIds = ids.filter(id => !placeholders[id]);
  if (newIds.length === 0) {
    resolve(Object.fromEntries(ids.map(id => [id, placeholders[id]])));
    return placeholders;
  } else {
    const values = Object.fromEntries(
      newIds.map((id) => [id, calculatePlaceholder(id, data)])
    );

    const newPlaceholders = { ...placeholders, ...values };
    resolve(Object.fromEntries(ids.map(id => [id, newPlaceholders[id]])));
    return newPlaceholders;
  }
};

const optimisticUpdatePlaceholder = (placeholder, change) => {
  if (Array.isArray(placeholder)) {
    // I don't know how to optimistic update an array
    return placeholder
  } else {
    return { ...placeholder, ...change }
  }
}

const optimisticUpdatePlaceholders = (placeholders, { slugs }) => {
  const updates = Object.fromEntries(
    Object.entries(slugs)
      .filter(([id, change]) => !!placeholders[id] && !!change)
      .map(([id, change]) => [id, optimisticUpdatePlaceholder(placeholders[id], change)])
  )
  return { ...placeholders, ...updates };
}

const placeholdersReducer = (state, { event, data }) => {
  switch (event) {
    case "reset":
      return {};
    case "refresh":
      return refreshPlaceholders(state, data);
    case "require":
      return ensurePlaceholdersLoaded(state, data);
    case "optimistic":
      return optimisticUpdatePlaceholders(state, data);
  }
};

/* Utils to build dynamic Dorpdowns tree */
const normalizeDynamicDropDownTree = tree => tree.map(node => ({
  ...node,
  id: node.slug,
  tree: normalizeDynamicDropDownTree(node.tree || []),
}));

/* Utils function to build the medical history options */
const buildFetusPositionTree = (positions, currentLanguage) => {
  if (!Array.isArray(positions)) return [];
  return positions
    .map(position => ({
      label: position.label?.[currentLanguage],
      id: position.id,
    }))
    .sort((a, b) => (a?.label?.includes("–") && !b?.label?.includes("–")) ? 1 : -1)
    .reduce((tree, position) => {
      if (!position?.label?.includes("–")) {
        tree.push(position);
        return tree;
      }
      const [category, subposition] = position.label.split("–");
      let categoryIndex = tree.findIndex(item => item.label === category);
      if (categoryIndex === -1) {
        categoryIndex = tree.length;
        tree.push({
          label: category,
          selectable: false,
          id: category,
          tree: [],
        });
      }
      tree[categoryIndex].tree.push({
        label: subposition.trim(),
        id: position.id,
      });
      return tree;
    }, []);
};

/* Utils function to fetch data from reportData */

const arrayLast = (array) => {
  if (array.length === 0)
    return undefined
  return array[array.length - 1]
}

const signatories = (reportData) => {
  return reportData.examination_data.signatories ?? []
}

const safePatientAge = (from, to) => {
  if (!from || !to) return null;
  return getPatientAge(from, to);
};

const safeConvertTimeZone = (date, timezone) => {
  if (!date) return null;
  if (!timezone) return null;
  return convertTimeZone(date, timezone);
}

const safeTimeFormatter = (date, format) => {
  if (!date) return null;
  if (!format) return null;
  return timeFormatter(date, format);
}

const stakeholders = (reportData) => {
  return reportData?.examination_data?.associated_contact_points || [];
}

const referringPhysician = (reportData) => {
  stakeholders(reportData).find(stakeholder => stakeholder.role === 'referring_provider');
}

const entities = (reportData) => {
  return reportData?.examination_data?.entities || [];
}

const gaString = (reportData) => {
  const gaValue = reportData?.v2_dating?.value;
  return gaValue ? getNiceGestionalAgeFromDays(__, gaValue) : "";
};

const fetuses = (reportData) => {
  return [{ id: null, dicom_id: 0 }].concat(reportData?.examination_data?.fetuses || []);
}

const fetusSexVisibility = (reportData) => {
  return reportData?.examination_data?.fetus_sex_visibility;
}

const prevUSGaValue = (reportData) => {
  return reportData?.episode?.prev_ultrasound_ga;
};

const prevUSGaString = (reportData) => {
  return prevUSGaValue(reportData) ? getNiceGestionalAgeFromDays(__, prevUSGaValue(reportData)) : "";
};

const examinationDate = (reportData) => {
  return safeConvertTimeZone(reportData?.examination_data?.examination_date, reportData?.site?.timezone || "UTC")?.substring(0, 10);
}

const inferChecklistItemStatus = (itemHasAnImage, itemShouldHaveAnImage, item) => {
  if (!isNullOrUndefined(item.status))
    return item.status;
  if (itemHasAnImage)
    return 'usual';
  if (itemShouldHaveAnImage)
    return 'non_visualized';
  return 'not_applicable';
}

const isChecklistItemPartOfTheExam = (mandatoryChecklistSlugs, item) => {
  const mandatoryIndex = mandatoryChecklistSlugs.indexOf(item.slug);
  if (mandatoryIndex >= 0) return mandatoryIndex + 1;
  if (item.status !== null && item.status !== "not_applicable") return true;
  return false;
}


const getPatientData = (reportData) => {
  return reportData?.patient
    ? {
      ...formatName(reportData.patient.name),
      ...reportData.patient
    }
    : {};
};

/* Context provider definition */
const XMLTemplateContextProvider = withTranslation()(({ t: __, children, XMLTemplateContext }) => {
  const { isFeatureFlagEnabled } = useAuth();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);
  const measurementsContext = useContext(MeasurementsContext);
  const { socket } = useContext(SocketContext);

  /* this defines the placeholders (without values) that are defined a priori via the static datas
   * If a placeholder is not defined, we check if it exists here.
   * If it does not, we use the usual workflow to define it.
   * If it does, we use the function defined here to get the placeholder.
   */
  const [placeholdersDefinition, dispatchPlaceholdersDefinition] = useReducer(placeholdersDefinitionReducer, {});
  const [reportDataOptions, doSetReportDataOptions] = useState({});
  const [newReportData, dispatchNewReportData] = useReducer(
    reportDataReducer,
    {
      loadeds: { dynamic: false, measurements: false },
      state: {},
      incr: 0
    }
  );
  const reportData = newReportData.state
  const reportDataIncr = newReportData.incr
  const loadingStatus = newReportData.loadeds
  const setReportDataDynamic = (data) => dispatchNewReportData({ event: "dynamic", data })
  const setReportDataOptions = (providedData) => {
    const data = { ...providedData, labels: measurementsContext.labels }
    doSetReportDataOptions(data)
    dispatchNewReportData({ event: "static", data })
  }


  const setReportDataMeasurements = (data) => dispatchNewReportData({ event: "measurements", data })
  const setReportData = (data) => dispatchNewReportData({ event: "internal", data })

  const [reportChannel, setReportChannel] = useState(null);
  const [placeholders, doDispatchPlaceholders] = useReducer(placeholdersReducer, {});
  const dispatchPlaceholders = (event, data = {}) =>
    doDispatchPlaceholders({ event, data: { ...data, placeholdersDefinition, reportData, dynamicDropdowns, measurementData: measurementsContext?.measurementData } });

  const setPlaceholders = (params) => {
    console.error("setPlaceholders is deprecated, use dispatchPlaceholders instead", params)
    return
  }

  /* requiredPlaceholers contains a key-value mapping between
   * a component (uniquely identified as much as possible) and 
   * the list of placeholders that are required for this component
   */
  const [requiredPlaceholders, dispatchRequiredPlaceholders] = useReducer(requiredPlaceholdersReducer, {});
  const [customPlaceholders, setCustomPlaceholders] = useState({});
  const [editingFieldId, setEditingFieldId] = useState({});
  const [examinationSite, setExaminationSite] = useState(null);
  const [mandatoryChecklistSlugs, setMandatoryChecklistSlugs] = useState([]);
  const [componentChecklistAssoc, setComponentChecklistAssoc] = useState({});
  const [dynamicDropdowns, setDynamicDropdowns] = useState({});
  const [automationTemplateFieldsVisible, setAutomationTemplateFieldsVisible] = useState(false);
  const [highlightedFields, setHighlightedFields] = useState([]); // example: [{id: "examination.method", icon: 'flash', iconClass: 'selected', source: 'ambientListening' }]
  const [autogeneratedChecklistComments, setAutogeneratedChecklistComments] = useState([]);
  const updateAutogeneratedChecklistComments = (fetus, collection, comments) => {
    if (!autogeneratedChecklistComments[fetus]) autogeneratedChecklistComments[fetus] = {};
    if (JSON.stringify(autogeneratedChecklistComments[fetus][collection]) !== JSON.stringify(comments)) {
      setAutogeneratedChecklistComments(c => {
        c[fetus][collection] = comments;
        return c;
      });
    }
  }

  const examId = examinationContext.examination?.id;
  const examinationFrozen = examinationContext.examination?.frozen;

  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const BIContext = {
    examination_status: reportData?.examination_data?.status,
    examination_preset_id: reportData?.examination_data?.preset_id,
    examination_id: reportData?.examination_data?.id,
    report_id: reportData?.report?.id,
    report_version: examinationContext?.examination?.report_version
  }

  const templateBlueprint = examinationContext.debugTemplate || reportData?.report_template?.blueprint;

  const addComponentChecklistAssoc = (componentId, itemSlugs) => setComponentChecklistAssoc(assoc => {
    assoc[componentId]?.filter(slug => !itemSlugs.includes(slug));
    assoc[componentId] = [...(assoc[componentId] || []), ...(itemSlugs)];
    return assoc;
  });

  const removeComponentChecklistAssoc = (componentId) => setComponentChecklistAssoc(assoc => {
    delete assoc[componentId];
    return assoc;
  });

  const updateReport = async (examId, changes, backupReport) => {
    try {
      const response = await ResourceApi.updateReport(examId, changes);
      setReportDataDynamic({ report: response?.data })
      return true;
    }
    catch (error) {
      console.error(error)
      if (backupReport)
        setReportData(backupReport);

      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
      return false;
    }
  }


  const loadMeasurementsReportData = async () => {
    await measurementsContext.loadMeasurementsData()
  };

  const loadFrozenReportData = async () => {
    if (!examId)
      return
    const response = await ResourceApi.getReportFrozen(examId);
    await measurementsContext.loadMeasurementsData()
    setReportDataOptions(response.data);
    loadDynamicReportData().then(() => loadMeasurementsReportData())
    dispatchNewReportData({ event: "frozen", data: response.data });
  }


  const loadDynamicReportData = async () => {
    if (!examId) return;
    const response = await ResourceApi.getReportDynamic(examId);
    setReportDataDynamic(response.data);
  };

  const loadStaticReportOptions = async () => {
    setDynamicDropdowns({});
    if (!examId) return;
    const response = await ResourceApi.getReportOptions(examId);
    setReportDataOptions(response.data);
  };


  const patientView = {
    /* No controller so not editable */
    'patient.name': {
      view: (patient, id) => ({
        value: patient.fullName,
        format: "string"
      })
    },
    'patient.lastname': {
      view: (patient, id) => ({
        value: patient.lastName,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, lastName: value })
    },
    'patient.firstname': {
      view: (patient, id) => ({
        value: patient.firstName,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, firstName: value })
    },
    'patient.middlename': {
      view: (patient, id) => ({
        value: patient.middleName,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, middleName: value })
    },
    'patient.title': {
      view: (patient, id) => ({
        value: patient.prefix,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, prefix: value })
    },
    'patient.dob': {
      view: (patient, id) => ({
        value: patient.dob,
        format: 'date'
      }),
      controller: (value, attrs) => ({ ...attrs, dob: value })
    },
    'patient.age': {
      /* No controller so not editable */
      view: (patient, id, context) => ({
        value: getPatientAge(patient.dob, context?.examination_data?.examination_date),
        format: 'string'
      })
    },
    'patient.address': {
      view: (patient, id) => ({
        value: patient.address,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, address: value })
    },
    'patient.sex': {
      view: (patient, id) => ({
        ...medicalHistoryOptions('patient.gender'),
        value: patient.sex,
      }),
      controller: (value, attrs) => ({ ...attrs, sex: value })
    },
    'patient.gender': {
      view: (patient, _id) => ({
        ...medicalHistoryOptions('patient.gender'),
        value: patient.sex,
      }),
      controller: (value, attrs) => ({ ...attrs, sex: value })
    },
    'patient.ehr_id': {
      /* No controller so not editable */
      view: (patient, id) => ({
        value: patient.ehr_id || patient.dicom_patient_id,
        format: 'string'
      })
    },
    'patient.mail': {
      view: (patient, id) => ({
        value: patient.email_address,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, email_address: value })
    }
  }

  /* Patient Controller */
  const updatePatient = async (attrs) => {
    if (!examinationContext.canEdit)
      return
    /* Here we can use the examinationContext as the examination is not frozen */
    const { lastName, firstName, middleName, prefix } = { ...formatName(examinationContext.patient?.name), ...attrs };

    if (!examinationContext.patient?.id) {
      createPatientAndAssignToCurrentExam(lastName, middleName, firstName, prefix, attrs.dob);
    } else {
      const updateAttrs = {
        name: createFullName(lastName.trim(), middleName.trim(), firstName.trim(), prefix.trim()),
        ...attrs
      };
      /* Optimistic update */
      const expectedPatient = { ...reportData.patient, ...updateAttrs }
      const backupReport = structuredClone(reportData);
      dispatchNewReportData({ event: "internal", data: { patient: expectedPatient } });
      /* Real update */
      examinationContext.updatePatient(examinationContext.patient.id, updateAttrs).catch(() => setReportData(backupReport))
    }
  };


  const convertMedicalHistoryOptionsToTree = (options) => {
    if (!options) return [];

    const newOptions = [{
      label: '',
      id: '',
      isRiskFactor: false,
    }, ...options.map(option => ({
      label: option.label[currentLanguage],
      id: option.value,
      isRiskFactor: !!option.is_risky,
    }))];
    return newOptions;
  }

  const updateEpisode = async (id, newValue) => {
    let field = id;
    switch (id) {
      case "edd":
        field = "estimated_delivery_date";
        break;
      case "lmp":
        field = "lmp_date";
        break;
    }

    try {
      measurementsContext.optimisticUpdateEpisode({ [field]: newValue });
      await examinationContext.updateEpisode({ [field]: newValue });
      return true;
    }
    catch (error) {
      console.error(error)
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
      return false;
    }
  };

  const createPatientAndAssignToCurrentExam = async (lastName, middleName, firstName, prefix, dob) => {
    /* This kind of operation should be done fully in the backend with an API such as
     *  /patient/create_and_attach/:examination_id
    */
    const patient = await examinationContext.createPatient({ firstName, lastName, dob });
    examinationContext.updateExamination({ patient_id: patient.id, episode_id: patient.current_episode_id })
  };

  const startEditingField = (placeholder, override = false) => {
    if (!examinationContext.canEdit) return;
    setEditingFieldId(placeholder);
  };

  /* batch version of onEndEditing */
  const applyChanges = async (slugs, opts) => {
    setEditingFieldId(false);
    if (!examinationContext.canEdit) return;

    const changes = {
      userEdits: {},
      examination_fetus: {},
      episode_fetus: {},
      episode: [],
      patient: {},
      medicalHistory: [],
      measurement: [],
    }

    /* optimistic update */
    //dispatchPlaceholders({ event: "optimistic", data: { slugs } });

    // group changes
    for (let [id, newChanges] of Object.entries(slugs).filter(slug => slug[1])) {

      let storeValueInUserEdits = true;
      let storeValueInMedicalHistory = true;

      if ((Object.keys(patientView).indexOf(id) != -1) && !isNullOrUndefined(newChanges?.value)) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        changes.patient = patientView[id].controller(newChanges.value, changes.patient)
      }

      if (id.startsWith('measurement.') || id.startsWith('identifier_measurement.')) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        changes.measurement.push({ id, newChanges });
      }


      if (id === "fetus.sex") {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;

        if (Array.isArray(newChanges.value)) {
          newChanges
            .value
            .slice(1)
            .map((sex, index) => {
              const fetus = examinationContext.examination.fetuses[index].fetus;
              sex = sex.toLowerCase();
              if (["unknown", "male", "female", ""].includes(sex) && sex != fetus.sex) {
                changes.episode_fetus[fetus.id] = { ...(changes.episode_fetus[fetus.id] || {}), sex };
              }
            });
        } else {
          const fetus = examinationContext.examination.fetuses[0].fetus;
          if (newChanges.value != fetus.sex) {
            changes.episode_fetus[fetus.id] = { ...(changes.episode_fetus[fetus.id] || {}), sex: newChanges.value };
          }
        }
      }

      if (id === "fetus.position") {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        if (Array.isArray(newChanges.value)) {
          newChanges
            .value
            .slice(1)
            .map((position_id, index) => {
              const fetus = examinationContext.examination.fetuses[index];
              if (position_id != fetus.position_id) {
                changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), position_id };
              }
            });
        } else {
          const fetus = examinationContext.examination.fetuses[0];
          const position_id = newChanges.value;
          if (position_id && position_id != fetus?.position_id) {
            changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), position_id };
          }
        }
      }

      if (id === "placenta.position" && !isNullOrUndefined(newChanges?.value)) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        if (Array.isArray(newChanges.value)) {
          newChanges
            .value
            .slice(1)
            .map((placenta_position_id, index) => {
              const fetus = examinationContext.examination.fetuses[index];
              if (placenta_position_id != fetus.placenta_position_id) {
                changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), placenta_position_id };
              }
            });
        } else {
          const fetus = examinationContext.examination.fetuses[0];
          if (placenta_position_id != fetus.placenta_position_id) {
            changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), placenta_position_id };
          }
        }
      }

      const idMH = templateIdToMedicalHistoryId(id);

      if ([
        "episode.estimated_delivery_date",
        "episode.conception_date",
        "episode.conception_method",
        "episode.lmp_date",
        "episode.cycle_length",
        "episode.embryo_transfer_date",
        "episode.embryo_transfer_day",
        "episode.prev_ultrasound_exam_date",
        "episode.prev_ultrasound_ga",
        "episode.prev_ultrasound_biometry_value",
        "episode.prev_ultrasound_option",
        "episode.edd_methods",
      ].includes(idMH) && !isNullOrUndefined(newChanges?.value)) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        changes.episode.push({ id: idMH.replace("episode.", ""), newChanges })
      }
      if (idMH && !isNullOrUndefined(newChanges?.value) && storeValueInMedicalHistory) {
        storeValueInUserEdits = false;
        const mhItem = medicalHistoryItem(id, examinationContext.medicalHistoryItems || {});
        changes.medicalHistory.push({ mhItem, newChanges });

        if ([
          "medicalexam.fetus.conception_date",
          "medicalexam.fetus.lmp",
          "medicalexam.fetus.edd",
          "medicalexam.fetus.embryo_transfer_date",
          "medicalexam.fetus.prev_ultrasound_exam_date"
        ].includes(idMH) && !isNullOrUndefined(newChanges?.value)) {
          storeValueInUserEdits = false;
          storeValueInMedicalHistory = false;
          changes.episode.push({ id: idMH.replace("medicalexam.fetus.", ""), newChanges })
        }
        continue;
      }

      if (!id.startsWith("measurement.")) {
        for (const [key, value] of Object.entries(newChanges)) {
          let shouldSave = true;
          const ignoredKeys = ["fetus"];
          if (key === "value" && !storeValueInUserEdits) shouldSave = false;
          if (ignoredKeys.indexOf(key) !== -1) shouldSave = false;
          if (key !== "value" && isNullOrUndefined(value)) shouldSave = false;

          if (shouldSave) {
            if (!changes.userEdits[id]) changes.userEdits[id] = {};
            changes.userEdits[id][key] = value;
          }
        }
      }
    }

    // optimistic update
    if (Object.keys(changes.userEdits).length) {
      const backupReport = structuredClone(reportData);
      dispatchNewReportData({ event: "user_edits", data: changes.userEdits || {} });
      Object.entries(changes.userEdits).map(([slug, newChanges]) => {
        onEndEditingBIEvent(slug, newChanges, opts.BIContext);
      })

      await updateReport(examId, changes.userEdits, backupReport);
    }

    // apply changes
    for (let entry of changes.measurement) {
      /* BI is sent directly in the onEndEditingMeasurement function */
      await onEndEditingMeasurement(entry.id, entry.newChanges, opts);
    }

    for (let [fetusId, change] of Object.entries(changes.examination_fetus)) {
      const fetus = examinationContext.examination.fetuses.find(f => f.id === parseInt(fetusId));
      const fetusIndex = examinationContext.examination.fetuses.findIndex(f => f.id === parseInt(fetusId)) + 1;
      if (fetus) {
        Object.entries(change).map(([identifier, value]) => {
          const identifiersToSlug = {
            position_id: "fetus.position",
            placenta_position_id: "placenta.position",
          };
          const slug = identifiersToSlug[identifier];
          onEndEditingBIEvent(slug, { value, fetus: fetusIndex, examination_fetus_id: fetus.id }, opts.BIContext);
        });
        await examinationContext.updateExaminationFetus(fetus, change);
      }
    }

    for (let [fetusId, change] of Object.entries(changes.episode_fetus)) {
      const fetus = examinationContext.examination.fetuses.find(f => f.fetus.id === parseInt(fetusId))?.fetus;
      const fetusIndex = examinationContext.examination.fetuses.findIndex(f => f.fetus.id === parseInt(fetusId)) + 1;

      if (fetus) {
        Object.entries(change).map(([identifier, value]) => {
          const identifiersToSlug = {
            sex: "fetus.sex",
          }
          const slug = identifiersToSlug[identifier];
          onEndEditingBIEvent(slug, { value, fetus: fetusIndex, episode_fetus_id: fetus.id }, opts.BIContext);
        })
        await examinationContext.updateEpisodeFetus(fetus, change);
      }
    }

    for (let entry of changes.medicalHistory) {
      const slug = entry.mhItem.text_id;
      onEndEditingBIEvent(slug, entry.newChanges, opts.BIContext);
      await examinationContext.updateMedicalHistory(entry.mhItem, entry.newChanges?.value, entry.newChanges?.value);
    }

    for (let entry of changes.episode) {
      onEndEditingBIEvent("episode." + entry.id, entry.newChanges, opts.BIContext);
      await updateEpisode(entry.id, entry.newChanges?.value);
    }

    if (Object.keys(changes.patient).length != 0) {
      for (let [key, value] of Object.entries(changes.patient)) {
        const identifiersToSlug = {
          firstName: "patient.firstname",
          lastName: "patient.lastname",
          middleName: "patient.middlename",
          title: "patient.title",
          fullName: "patient.name",
          dob: "patient.dob",
          address: "patient.address",
          sex: "patient.sex",
          email_address: "patient.mail",
        }
        const slug = identifiersToSlug[key];
        onEndEditingBIEvent(slug, { value }, opts.BIContext);
      }
      await updatePatient(changes.patient)
    }
  }

  const onEndEditing = async (id, newChanges, custom = false, opts = {}) => {
    setEditingFieldId(false);
    if (!examinationContext.canEdit) return;

    if (custom) id = `custom.${id}`;

    applyChanges({ [id]: newChanges }, opts);
  };

  const onEndEditingDynamicDropdown = async (slug, newChanges, custom = false, opts = {}) => {
    setEditingFieldId(false);
    if (!examinationContext.canEdit) return;

    if (custom) slug = `custom.${slug}`;
    const dynamicDropdownId = dynamicDropdowns[slug]?.id;

    let enrichedChanges = null;

    if (dynamicDropdownId) {
      // get the full option
      const enrichValue = async (value, optionSlug) => (value && !value.loaded)
        ? {
          ...value,
          description: (await ResourceApi.getDynamicDropdownOption(examinationContext.examination.id, dynamicDropdownId, optionSlug))?.data?.value,
          loaded: true,
        }
        : value;

      if (typeof newChanges?.value === "object") {
        /* multi-choice dropdown */
        let enrichedValue = {};

        for (const [optionSlug, changes] of Object.entries(newChanges?.value)) {
          enrichedValue[optionSlug] = await enrichValue(changes, optionSlug);
        }

        enrichedChanges = {
          ...newChanges,
          value: enrichedValue,
        };

      } else if (Array.isArray(newChanges?.value)) {
        /* single-choice multi-fetal dropdown */
        // based on the current data-model, it's not possible to load descriptions here
        enrichedChanges = newChanges;

      } else {
        /* single-choice dropdown */
        enrichedChanges = {
          ...newChanges,
          description: enrichValue(changes.value)?.description
        };
      }

    } else {
      enrichedChanges = newChanges;
    }

    applyChanges({ [slug]: enrichedChanges }, opts);
  };

  const onEndEditingChecklist = async (value, checklistItem, opts = {}) => {
    if (!checklistItem) return;
    const checklistItems = Array.isArray(checklistItem) ? checklistItem : [checklistItem];

    // optimistic update
    let optimisticChecklistItems = structuredClone(reportData.checklist_items_with_statuses);
    for (const item of checklistItems) {
      const checklist_item = { ...item, status: value };
      optimisticChecklistItems = updateChecklistItemStatus(optimisticChecklistItems, checklist_item);
    }
    dispatchNewReportData({ event: "checklist_items_with_statuses", data: optimisticChecklistItems })
    /* this is not super optimized. We can improve this by moving this operation as one single API call */

    const localBIContext = opts.BIContext ? opts.BIContext : { ...BIContext, page: "checklist-items" };
    checklistItems.forEach(({ slug, fetus, examination_fetus_id }) => {
      onEndEditingBIEvent(`checklist.item.${slug}`, { value, fetus, examination_fetus_id }, localBIContext);
    });

    try {
      await ResourceApi.updateChecklistItemStatus(examinationContext.examination.id, checklistItems.map(item => item.id), value, checklistItems?.[0]?.examination_fetus_id);
      loadDynamicReportData();
    }
    catch (error) {
      loadDynamicReportData();
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
    }
  };

  const onEndEditingMeasurement = async (id, newChanges, opts = {}) => {
    if (newChanges.fetus == 0) newChanges.fetus = "patient"

    if (id.startsWith("measurement.")) {
      if (!isNullOrUndefined(newChanges.value)) {
        const values = { edit: newChanges.value };
        newChanges.values = values;
        delete newChanges.value;
      }
      newChanges.measurement_id = id.replace("measurement.", "");
    }

    if (id.startsWith('identifier_measurement.')) {
      newChanges.measurement_id = id.replace("identifier_measurement.", "");
    }

    const localBIContext = { ...opts.BIContext, "data-id": id };
    return measurementsContext.updateMeasurements(newChanges, { BIContext: localBIContext });
  }

  const onEndEditingDating = async (newChanges, opts = {}) => {
    setEditingFieldId(false);
    const update = { "dating.table": { "value": newChanges } };
    onEndEditingBIEvent("dating.table", newChanges, opts.bi ?? {});
    const updateReportOk = await updateReport(examId, update);
    loadMeasurementsReportData();
    return updateReportOk;
  };

  const onEndEditingBIEvent = (id, changes, BIContext) => {
    if (Array.isArray(changes)) {
      changes = changes
        .filter((change) => !isNullOrUndefined(change))
        .map((change, fetus) => ({ ...change, fetus, examination_fetus_id: fetuses[fetus]?.id }))
        .forEach((changes) => {
          ResourceApi.createBIEvent({
            ...BIContext,
            changes,
            "data-id": id,
            event_type: "report_field_edited",
          })
        });
    } else {
      ResourceApi.createBIEvent({
        ...BIContext,
        "data-id": id,
        changes,
        event_type: "report_field_edited",
      });
    }
  }

  const setAssignedGa = async (assignedMethodId, assignedExamId, assignedFetus) => {
    try {
      await ResourceApi.updateAssignedGa(examId, assignedExamId, assignedMethodId, assignedFetus);
      await examinationContext.refreshDating()
      return true;
    }
    catch (error) {
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
      return false;
    }
  };

  const revertAssignedGa = async () => {
    try {
      await ResourceApi.revertAssignedGaToPrevExam(examId);
      await loadDynamicReportData();
      await loadMeasurementsReportData();
      await examinationContext.refreshDating()
    }
    catch (error) {
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
    }
  };

  const getSlugFromLabel = (label) => label?.toLowerCase().replace(/[\W_]+/g, "_");

  const medicalHistoryOptions = (id, value) => {
    const item = examinationContext.medicalHistoryItems[id]
    if (!item)
      return {}

    const itemFormatFromMHType = (type) => {
      switch (type) {
        case "multiselect": return "multiple";
        case "buttongroup":
        case "text":
        case "select":
          return "string";
        case "date":
          return "date";
        case "number":
          return "number";
        default:
          return "string";
      }
    }

    const getMHOptionLabel = (item, value) => {
      if (!item.options?.length) return value;
      if (Array.isArray(value)) return value.map(value => item.options.find(option => option.value === value)?.label[currentLanguage]);
      return item.options.find(option => option.value === value)?.label[currentLanguage];
    }

    return {
      label: getMHOptionLabel(item, value ?? item.default),
      fieldLabel: item.label[currentLanguage],
      tree: convertMedicalHistoryOptionsToTree(item.options),
      type: item.type,
      format: itemFormatFromMHType(item.type),
    }
  };

  const getCarryForwardWithProps = (props, fieldType = "") => {
    const carryForwards = reportData.carry_forward || {};
    const id = `${props.custom === "true" ? 'custom.' : ''}${props.data}`;
    const isArray = Array.isArray(carryForwards?.[id]);
    const carryForward = isArray ? carryForwards?.[id] : [carryForwards?.[id]];

    const output = carryForward.map(p => ({
      ...(carryForwards?.[`sonio.custom.${fieldType}`] || {}),
      ...(p || {}),
      data: props?.data,
      custom: props?.custom
    }));

    return isArray ? output : output[0];
  };

  // If the field needs to be highlighted, then return the object (with icon), otherwise return false.
  // Example [{id: "examination.method", icon: 'flash'}]
  const getHighligthedWithProps = (props, fieldType = "") => {
    const slug = (props.custom === "true" ? "custom." : "") + props.data;
    return highlightedFields.find(e => e.slug === slug);
  }

  /* flash report field highlight */
  const isFlashReportField = useCallback((slug) => {
    // We support only custom, methods, indications and findings to be automated with flash reports now.
    return Object.keys(customPlaceholders).includes(slug) || slug.startsWith === "custom." || ["examination.method", "examination.indication", "examination.finding"].includes(slug);
  }, [customPlaceholders]);

  useEffect(() => {
    if (automationTemplateFieldsVisible) {
      setHighlightedFields(fields => {
        const allSlugs = [...Object.keys(placeholders), ...Object.keys(customPlaceholders)];
        return allSlugs.filter(slug => isFlashReportField(slug)).map(slug => ({
          slug: slug,
          icon: "flash",
          iconClass: "",
          source: 'flashReport'
        }))
      });
    }

    return () => {
      setHighlightedFields(fields => fields.filter(field => field.source !== 'flashReport'));
    }
  }, [automationTemplateFieldsVisible, isFlashReportField]);
  /* end of flash report field highlight */

  const getMeasurementObject = (measurementId, measurement, fetusId) => {
    const measurementLabel = measurementsContext?.labels?.measurement?.[measurement.measurement]?.label?.[currentLanguage] || measurement.measurement;
    const siteLabel = measurementsContext?.labels?.body_structure?.[measurement.site]?.label?.[currentLanguage] || measurement.site;
    const lateralityLabel = measurementsContext?.labels?.laterality?.[measurement.laterality]?.label?.[currentLanguage] || measurement.laterality;

    let label = measurementLabel;
    if (siteLabel) label += `, ${siteLabel}`;
    if (lateralityLabel) label += ` (${lateralityLabel})`;

    const availableDerivations = (measurement?.all_values || []).map(val => ({ value: val.y, derivation: val.derivation }));

    let user_edit = measurementsContext.measurementData.measurement_edits?.[fetusId]?.[`${measurementId}`];
    let previousMeasurements = [];
    if (reportDataOptions?.previous_exams) {
      previousMeasurements = Object.values(reportDataOptions?.previous_exams)
        .map((examData) => {
          const measurements = examData?.measurements?.measurements;
          const selectedValue = (fetusId === "patient" ?
            measurements?.patient : measurements?.fetus?.[fetusId]
          )?.[measurementId]?.selected_value;

          return { examId, value: selectedValue?.y, xvalue: selectedValue?.x };
        })
        .filter(v => v.value && v.xvalue);
    }

    const percentiles = measurement.selected_value?.percentiles;
    return ({
      editedLabel: user_edit?.label,
      label,
      value: measurement.selected_value?.y, // Report edits are taken into account in derivations
      xvalue: measurement.selected_value?.x,
      units: measurementsContext?.labels?.measurement?.[measurement.measurement]?.units,
      comment: user_edit?.comment,
      visible: user_edit?.visible ?? null,
      visible_graph: user_edit?.visible_graph ?? null,
      curve_slug: measurement?.selected_value?.curve,
      percentile: measurement.selected_value?.sonio_percentile,
      zscore: measurement.selected_value?.sonio_zscore,
      reference_value: measurement.selected_value?.reference_value,
      estimation: measurement.selected_value?.estimation || false,
      derivation: measurement.selected_value?.derivation || false,
      availableDerivations,
      format: "number",
      previousMeasurements,
      percentiles,
      availableCurveSlugs: Object.keys(percentiles || {})
    });
  };

  /* Load static data */
  useEffect(() => {
    if (examinationFrozen)
      return
    if (!examinationContext.examination.id || !examinationContext.examination.preset_id)
      return
    /* Make sure everything is reseted so we have no weird state of undefined data making Oops page pop */
    dispatchPlaceholders("reset");
    dispatchNewReportData({ event: "reset" });
    doSetReportDataOptions({});
    loadStaticReportOptions();
  },
    [
      examinationContext.examination.id,
      examinationContext.examination.site_id,
      examinationContext.examination.preset_id,
      examinationFrozen
    ]);

  useEffect(() => {
    doSetReportDataOptions((reportDataOptions) => ({ ...reportDataOptions, labels: measurementsContext.labels }));
  }, [measurementsContext.labels, examinationFrozen]);

  /* Load dynamic data */
  useEffect(() => {
    if (examinationFrozen)
      return
    if (!examinationContext.examination.id || !examinationContext.examination.preset_id)
      return
    if (!examinationContext.episode)
      return

    loadDynamicReportData().then(() => loadMeasurementsReportData())
  }, [
    examinationFrozen,
    JSON.stringify({
      ...examinationContext.examination,
      patient: {
        ...(examinationContext.examination?.patient || {}),
        /* Ignore modifications on those fields in the patient */
        medical_history: []
      },
      /* Ignore modifications on those fields */
      updated_at: '',
      events: [],
      medical_history: [],
      procedures_codes: [],
      diagnoses_codes: [],
    })
  ]);

  /* Load measurements */
  useEffect(() => {
    if (examinationFrozen)
      return
    setReportDataMeasurements(measurementsContext.measurementData);
  }, [measurementsContext.measurementData, examinationFrozen]);

  useEffect(() => {
    if (!examinationFrozen)
      return
    loadFrozenReportData();
  }, [examinationFrozen, examId])

  const additionalLoading = {
    examination: !!examinationContext.examination?.id
  }

  const applyAutomationTemplate = (slug) => {
    if (examinationContext.examination?.preset_id && slug) {
      ResourceApi.getAutomationTemplate(examinationContext.examination.preset_id, slug).then(resp => {
        evaluateAutomationTemplate(resp.data.data.template, { type: "automation_template", slug: resp.data.data.slug });
      })
    }
  }

  const evaluateAutomationTemplate = async (templateStr, source) => {
    // At the end of the template add the assigns_to_json - to convert all assigned items to JSON structure.
    let template = templateStr + `\n{% assigns_to_json %}`;

    // Prepare the input data for the template.
    // Note: we replace all dots in the keys with double underscores since LiquidJS does not support dots in keys.
    const backupReport = structuredClone(reportData);
    let data = replaceAllKeys({
      user_edits: backupReport.report.user_edits,
      measurements: backupReport.measurements
    }, ".", "__");

    let output = evalTemplate(template, data);
    let newUserEdits = replaceAllKeys(JSON.parse(output), "__", ".");

    // We only support keys starting with "custom." now (for security)
    // Also format the value to an object, and assume a single fetus now
    let processedNewUserEdits = {};
    for (const [key, value] of Object.entries(newUserEdits)) {
      if (key.startsWith("custom.")) {
        // If the value is a string we assume single fetus 
        if (typeof value === "string") {
          processedNewUserEdits[key] = {
            value: ["", value],
            source: source
          };
        }
        else {
          processedNewUserEdits[key] = {
            value: value,
            source: source
          };
        }
      }

      if (key === "examination.method") {
        let valueList = value.split(";").map(v => v.trim());
        processedNewUserEdits[key] = { value: await buildUserEditKeyForMethod(valueList), source: source };
      }

      if (key === "examination.indication" || key === "examination.finding") {
        // Currently in the template we define each item on one line.
        // They need to be merged into one object. 
        let allValues = Object.values(value).reduce((acc, item) => ({ ...acc, ...item }), {});
        processedNewUserEdits[key] = { value: allValues, source: source };
      }

      // Special keys 
      if (key === "fetalAnatomy") {
        // Build a map of slug -> checklistItem 
        let slugCIMap = backupReport.checklist_items.reduce((acc, item) => {
          acc[item.slug] = item;
          return acc;
        }, {})

        // For each status, update the checklist items
        Object.entries(value).forEach(([status, values]) => {
          // Repeat this for each valid fetus
          fetuses.forEach(examFetus => {
            let fetusId = examFetus.id;
            if (fetusId) {
              let checklistItems = values.map(v => ({ ...slugCIMap[v], examination_fetus_id: fetusId }));
              onEndEditingChecklist(status, checklistItems);
            }
          });
        });
      }
    }

    let changes = { ...backupReport.report.user_edits, ...processedNewUserEdits };

    dispatchNewReportData({ event: "user_edits", data: changes || {} });
    updateReport(examinationContext.examination.id, changes, backupReport);
  }

  // Builds the user_edit object for storing methods.
  const buildUserEditKeyForMethod = async (keys) => {
    let methodKeys = keys.map(key => `method.${key}`);

    const dynamicDropdownId = dynamicDropdowns["examination.method"]?.id;

    // Fetch all the included methods
    let methodsResponse = await Promise.all(methodKeys.map(key => ResourceApi.getDynamicDropdownOption(examinationContext.examination.id, dynamicDropdownId, key)));

    return methodsResponse.reduce((acc, resp, idx) => {
      let method = resp?.data;

      if (method && method.selectable) {
        acc[method.slug] = { label: method.label, description: method.description, value: true, order: idx };
        return acc;
      }

      return acc;
    }, {});
  }

  const flattenMethodNestedCollections = (tree) => {
    if (!tree) return [];

    return tree.reduce((acc, item) => {
      acc.push({ id: item.id, selectable: item.selectable, label: item.label, description: item.description });

      if (item.tree.length > 0) {
        acc.push(...flattenMethodNestedCollections(item.tree));
      }

      return acc;
    }, []);
  }

  const generateAutomationTemplate = async (setTemplateCallback = null) => {
    let template = Object.keys(reportData.report.user_edits).filter((key) => key.startsWith("custom.")).map((key) => {
      return '{% assign ' + key.replaceAll(".", "__") + ' = "' + reportData.report.user_edits[key].value[1] + '" %}';
    }).join("\n");

    if (setTemplateCallback) {
      setTemplateCallback(template);
    }

    return template;
  }

  const loadDynamicDropdownFullTree = async (slug) => {
    console.log("Loading dynamic dropdown full tree for ", slug)
    const dropdownId = dynamicDropdowns[slug]?.id;
    const shouldLoad = !dynamicDropdowns[slug]?.loaded && !dynamicDropdowns[slug]?.loading;
    const hasSubNodes = dynamicDropdowns[slug]?.tree?.some(node => !node.selectable);

    if (dropdownId && shouldLoad && hasSubNodes) {
      setDynamicDropdowns(dropdowns => ({
        ...dropdowns, [slug]: {
          ...dropdowns[slug],
          loading: true,
        }
      }))

      ResourceApi.getDynamicDropdownFullTree(examinationContext.examination.id, dropdownId).then(response => {
        if (response.status === 200) {
          setDynamicDropdowns(dropdowns => ({
            ...dropdowns, [slug]: {
              ...response.data,
              loaded: true,
              loading: false,
            }
          }));
        } else {
          setDynamicDropdowns(dropdowns => ({
            ...dropdowns, [slug]: {
              ...dropdowns[slug],
              loaded: false,
              loading: false,
            }
          }));
        }
      });
    }
  };

  /* Checklist items computed utils variables */
  const checklistItemsMap = useMemo(() => {
    if (!reportData?.checklist_items) return {};
    return reportData.checklist_items.reduce((map, item) => {
      map[item.id] = item;
      return map;
    }, {});
  }, [reportData?.checklist_items]);

  const refreshPlaceholders = async () => {
    dispatchPlaceholders("refresh");

    // This is completely wrong, Guillaume is taking this point to see how we can fix this
    /* 
    for (const item of (reportData?.checklist_items?.filter(c => c.parents?.length > 0) || [])) {
      for (const parent of item.parents) {
        if (item.unusual) {
          const id = `checklist.item.${parent.slug}`;
          if (placeholders[id]) {
            for (const f of fetuses(reportData)) {
              placeholders[id][f].active = true;
              placeholders[id][f].normal = item.normal;
              placeholders[id][f].unusual = item.unusual;
              placeholders[id][f].children = [...(placeholders[id][f]?.children || []), item];
            }
          }
        }
      }
    }
    */
  };

  useEffect(() => refreshPlaceholders(),
    [
      reportDataIncr,
      dynamicDropdowns,
      JSON.stringify(examinationContext.fetusSexVisibility),
      JSON.stringify(examinationContext.instances),
      placeholdersDefinition
    ]
  );

  /* Begin placeholders definition */
  /* Define all the hard coded placeholders always present */
  useEffect(() => {
    dispatchPlaceholdersDefinition({
      event: "add",
      data: {
        "examination.id": {
          view: (reportData) => ({
            value: reportData?.examination_data?.id,
            format: "string",
            editable: false
          }),
        },
        "examination.status": {
          view: (reportData) => ({
            value: reportData?.examination_data?.status,
            label: __("examinationReview.status." + reportData?.examination_data?.status),
            format: "string",
            editable: false
          }),
        },
        /* TODO implement the controller */
        "examination.date": {
          view: (reportData) => ({
            value: examinationDate(reportData),
            raw_value: reportData?.examination_data?.examination_date,
            format: "date"
          }),
        },
        "examination.trimester": {
          /* TODO the trimester should depend from the dating */
          view: (reportData) => ({
            value: reportData?.examination_data?.trimester,
            format: "string",
            editable: false
          })
        },
        "examination.finding": {
          view: () => ({
            format: "multiple"
          })
        },
        "examination.indication": {
          view: () => ({
            format: "multiple"
          })
        },
        "examination.signed": {
          view: (reportData) => ({
            value: !!reportData?.examination_data?.signed_data?.inserted_at,
            editable: false
          })
        },
        "examination.signed.date": {
          view: (reportData) => ({
            value: reportData?.examination_data?.signed_data?.frozen_inserted_at.date,
            format: "date",
            editable: false
          })
        },
        "examination.signed.time": {
          view: (reportData) => ({
            value: reportData?.examination_data?.signed_data?.frozen_inserted_at.time,
            format: "string",
            editable: false
          })
        },
        "examination.signed.title": {
          view: (reportData) => ({
            value: reportData?.examination_data?.signed_data?.entity_title,
            format: "string",
            editable: false
          })
        },
        "examination.signatories.first.date": {
          view: (reportData) => ({
            value: signatories(reportData)[0]?.frozen_inserted_at?.date,
            format: "date",
            editable: false
          })
        },
        "examination.signatories.first.time": {
          view: (reportData) => ({
            value: signatories(reportData)[0]?.frozen_inserted_at?.time,
            format: "time",
            editable: false
          })
        },
        "examination.signatories.first.title": {
          view: (reportData) => ({
            value: signatories(reportData)[0]?.entity_title,
            format: "title",
            editable: false
          })
        },
        "examination.signatories.last.date": {
          view: (reportData) => ({
            value: arrayLast(signatories(reportData))?.frozen_inserted_at?.date,
            format: "date",
            editable: false
          })
        },
        "examination.signatories.last.time": {
          view: (reportData) => ({
            value: arrayLast(signatories(reportData))?.frozen_inserted_at?.time,
            format: "time",
            editable: false
          })
        },
        "examination.signatories.last.title": {
          view: (reportData) => ({
            value: arrayLast(signatories(reportData))?.entity_title,
            format: "title",
            editable: false
          })
        },
        "examination.revised": {
          view: (reportData) => ({
            value: reportData?.examination_data?.signatories?.length > 1,
            format: "boolean",
            editable: false
          })
        },
        "examination.signatories": {
          view: (reportData) => ({
            value: Object.assign({}, reportData.examination_data.signatories.map((s) => ({
              date: s.frozen_inserted_at.date,
              time: s.frozen_inserted_at.time,
              title: s.entity_title,
            }))),
            format: "multiple",
            editable: false

          })
        },
        "examination.signatories.count": {
          view: (reportData) => ({
            value: reportData?.examination_data?.signatories?.length,
            format: "number",
            editable: false
          })
        },
        "examination.revisers": {
          view: (reportData) => ({
            value: Object.assign({}, reportData.examination_data.signatories.slice(1).map((s) => ({
              date: s.frozen_inserted_at.date,
              time: s.frozen_inserted_at.time,
              title: s.entity_title,
            }))),
            format: "multiple",
            editable: false

          })
        },
        "examination.revisers.count": {
          view: (reportData) => ({
            value: (reportData?.examination_data?.signatories?.length || 1) - 1,
            format: "number",
            editable: false
          })
        },
        "examination.submitted": {
          view: (reportData) => ({
            value: !!reportData?.examination_data?.submitted_data?.inserted_at,
            editable: false
          })
        },
        "examination.submitted.date": {
          view: (reportData) => ({
            /* TODO fetch the timezone from the reportData */
            value: reportData?.examination_data?.submitted_data?.frozen_inserted_at.date,
            format: "date",
            editable: false
          })
        },
        "examination.submitted.time": {
          view: (reportData) => ({
            value: reportData?.examination_data?.submitted_data?.frozen_inserted_at.time,
            format: "string",
            editable: false
          })
        },
        "examination.submitted.title": {
          view: (reportData) => ({
            value: reportData?.examination_data?.submitted_data?.entity_title,
            format: "string",
            editable: false
          })
        },
        "examination.import.id": {
          view: (reportData) => ({
            value: reportData?.examination_data?.imported_id,
            format: "string",
            editable: false
          })
        },
        "examination.import.source": {
          view: (reportData) => ({
            value: reportData?.examination_data?.import_source,
            format: "string",
            editable: false
          })
        },
        "patient.import.id": {
          view: (reportData) => ({
            value: reportData?.patient?.imported_id,
            format: "string",
            editable: false
          })
        },
        "patient.import.source": {
          view: (reportData) => ({
            value: reportData?.patient?.import_source,
            format: "string",
            editable: false
          })
        },
        "examination.stakeholders": {
          view: (reportData) => ({
            value: Object.assign({}, stakeholders(reportData).map(stakeholder => ({
              ...(stakeholder.contact_point || {}),
              name: stakeholder.contact_point.name ? formatName(stakeholder.contact_point.name).fullName : "",
              address: __("examinationStakeholder.fullAddress", stakeholder.contact_point?.address).replace(/[ ,\(\)]+$/g, "").replace(/^[ ,\(\)]+/g, ""),
              role: __(`examinationStakeholder.role.external.${stakeholder.role}`),
            }))),
            format: "multiple",
            editable: false
          })
        },
        "examination.external_hospitals": {
          view: (reportData) => ({
            value: Object.assign({}, stakeholders(reportData).filter(stakeholder => stakeholder.role !== "referring_provider").map(stakeholder => ({
              ...(stakeholder.contact_point || {}),
              name: stakeholder.contact_point.name ? formatName(stakeholder.contact_point.name).fullName : "",
              address: __("examinationStakeholder.fullAddress", stakeholder.contact_point?.address).replace(/[ ,\(\)]+$/g, "").replace(/^[ ,\(\)]+/g, ""),
              role: __(`examinationStakeholder.role.external.${stakeholder.role}`),
            }))),
            format: "multiple",
            editable: false
          })
        },
        "referring_physician.name": {
          view: (reportData) => {
            const name = referringPhysician(reportData)?.contact_point.name ? formatName(referringPhysician.contact_point.name).fullName : "";
            return {
              value: name,
              format: "string",
            };
          }
        },
        "referring_physician.phone": {
          view: (reportData) => ({
            value: referringPhysician(reportData)?.contact_point.phone || "",
            format: "string"
          })
        },
        "referring_physician.fax": {
          view: (reportData) => ({
            value: referringPhysician(reportData)?.contact_point.fax || "",
            format: "string"
          })
        },
        "referring_physician.email": {
          view: (reportData) => ({
            value: referringPhysician(reportData)?.contact_point.email || "",
            format: "string"
          })
        },
        "referring_physician.id": {
          view: (reportData) => ({
            value: referringPhysician(reportData)?.contact_point?.external_id || "",
            format: "string",
            editable: false
          })
        },
        "referring_physician.address": {
          view: (reportData) => ({
            value: __("examinationStakeholder.fullAddress", referringPhysician(reportData)?.contact_point?.address || {}).replace(/[ ,\(\)]+$/g, "").replace(/^[ ,\(\)]+/g, ""),
            format: "string"
          })
        },
        "examination.entities": {
          view: (reportData) => ({
            value: Object.assign({}, entities(reportData).map(entity => ({
              ...(entity.contact_point || {}),
              name: entity.entity?.title,
              country: entity.entity?.country_id,
              role: __(`examinationStakeholder.role.${entity.role}`),
            }))),
            format: "multiple",
            editable: false
          })
        },
        "site.name": {
          view: () => ({
            format: "string",
            editable: false
          })
        },
        "site.address": {
          view: () => ({
            format: "string",
            editable: false
          })
        },
        "site.notes": {
          view: () => ({
            format: "string",
            editable: false
          })
        },
        "site.phone": {
          view: () => ({
            format: "string",
            editable: false
          })
        },
        "site.website": {
          view: () => ({
            format: "string",
            editable: false
          })
        },
        "practitioner.title": {
          view: () => ({
            format: "string",
          })
        },
        "practitioner.name": {
          view: () => ({
            original_id: "practitioner.title",
          })
        },
        "practitioner.rpps": {
          view: () => ({
            format: "string",
          })
        },
        "practitioner.id": {
          view: () => ({
            original_id: "practitioner.rpps",
          })
        },
        "reader.title": {
          view: () => ({
            format: "string",
          })
        },
        "reader.name": {
          view: () => ({
            original_id: "reader.title",
          })
        },
        "reader.rpps": {
          view: () => ({
            format: "string",
          })
        },
        "reader.id": {
          view: () => ({
            original_id: "reader.rpps",
          })
        },
        // TODO deprecated for exmination.ga
        "patient.ga": {
          view: (reportData) => ({
            value: gaString(reportData),
            format: "string",
            editable: false
          })
        },
        "examination.ga": {
          view: (reportData) => ({
            value: gaString(reportData),
            format: "string",
            editable: false
          })
        },
        "ga.assigned": {
          view: (reportData) => ({
            value: gaString(reportData),
            raw_value: reportData?.v2_dating?.value,
            format: "string",
            editable: false
          })
        },
        "examination.edd": {
          view: () => ({
            value: "Not implemented .... Please contact the support",
            editable: false
          })
        },
        "ga.assigned.value": {
          view: (reportData) => ({
            value: reportData?.v2_dating?.value,
            format: "number",
            editable: false
          })
        },
        "ga.assigned.exam": {
          view: (reportData) => ({
            value: reportData?.v2_dating?.assigned_exam_id,
            format: "number",
            editable: false
          })
        },
        "ga.assigned.fetus": {
          view: (reportData) => ({
            value: reportData?.v2_dating?.assigned_fetus,
            format: "number",
            editable: false
          })
        },
        // TODO to make it editable we need to make this as a tree + give the label
        "ga.assigned.method": {
          view: (reportData) => ({
            value: reportData?.v2_dating?.assigned_method_id,
            format: "string",
            editable: false
          })
        },
        "ga.assigned.selected_at": {
          view: (reportData) => ({
            value: safeConvertTimeZone(reportData?.v2_dating?.selected_at, reportData?.site?.timezone || "UTC"),
            raw_value: reportData?.v2_dating?.selected_at,
            format: "date",
            editable: false
          })
        },
        // TODO deprecated for episode.conception_date
        "patient.conception_date": {
          view: (reportData) => ({
            value: reportData?.episode?.conception_date,
            format: "date",
          })
        },
        "episode.conception_date": {
          view: () => ({
            format: "date",
          })
        },
        "patient.lmp_date": {
          view: (reportData) => ({
            value: reportData?.episode?.lmp_date,
            format: "date",
          })
        },
        "episode.lmp_date": {
          view: () => ({
            format: "date",
          })
        },
        // TODO deprecated for examination.nb_fetuses
        "patient.nb_fetuses": {
          view: (reportData) => ({
            value: reportData?.examination_data?.fetuses?.length || 1,
            format: "number",
            editable: false
          })
        },
        "examination.nb_fetuses": {
          view: (reportData) => ({
            value: reportData?.examination_data?.fetuses?.length || 1,
            format: "number",
            editable: false
          })
        },
        "fetus.dicom_id": {
          view: (reportData) => ({
            value: fetuses(reportData).map((f) => f.dicom_id),
            format: "number"
          })
        },
        "fetus.number": {
          view: () => ({
            original_id: "fetus.dicom_id",
          })
        },
        "fetus.name": {
          view: (reportData) => ({
            value: fetuses(reportData).map((f) => f.fetus?.label || "-"),
            format: "string"
          })
        },
        "fetus.examination_id": {
          view: (reportData) => ({
            format: fetuses(reportData).map((f) => f.id),
            format: "number"
          })
        },
        "fetus.episode_id": {
          view: (reportData) => ({
            format: fetuses(reportData).map((f) => f.fetus?.id),
            format: "number"
          })
        },
        // TODO make it as a tree + give the label + editable
        "fetus.sex_visibility": {
          view: (reportData) => ({
            value: fetusSexVisibility(reportData),
            format: "string",
            editable: false

          })
        },
        "fetus.position": {
          view: (reportData) => ({
            label: __("examinationReview.fetusPosition"),
            value: fetuses(reportData).map((f) => (f.position_id)),
            tree: buildFetusPositionTree(reportData?.fetus_positions, currentLanguage),
            format: "number"
          }),
        },
        "placenta.position": {
          view: (reportData) => ({
            value: fetuses(reportData).map((f) => (f.placenta_position_id)),
            tree: reportData?.placenta_positions?.map(position => ({
              label: position.label[currentLanguage],
              id: position.id,
            })) || [],
            format: "number"
          })
        },
        "episode.estimated_delivery_date": {
          view: () => ({
            format: "date"
          })
        },
        "episode.conception_method": {
          view: () => ({
            format: "sting"
          })
        },
        // TODO max and min value
        "episode.cycle_length": {
          view: () => ({
            format: "number"
          })
        },
        "episode.embryo_transfer_date": {
          view: () => ({
            format: "date"
          })
        },
        // TODO max and min value
        "episode.embryo_transfer_day": {
          view: () => ({
            format: "number"
          })
        },
        "episode.prev_ultrasound_exam_date": {
          view: () => ({
            format: "date"
          })
        },
        "episode.prev_ultrasound_ga": {
          view: (reportData) => ({
            value: prevUSGaString(reportData),
            format: "string",
            editable: false
          }),
        },
        // TODO this is a dynamic unit field -> see how to do with Roberto
        "episode.prev_ultrasound_biometry_value": {
          view: () => ({
            format: "number"
          })
        },
        "episode.prev_ultrasound_option": {
          view: () => ({
            format: "string"
          })
        },
        // TODO this is a multiple field add the tree to it
        "episode.edd_methods": {
          view: () => ({
            format: "string"
          })
        },
        "medicalexam.fetus.conception_date": {
          view: () => ({
            original_id: "episode.conception_date"
          })
        },
        // TODO deprecated
        "medicalexam.fetus.lmp_date": {
          view: () => ({
            original_id: "episode.lmp_date"
          })
        },
        // TODO deprecated
        "medicalexam.fetus.edd": {
          view: () => ({
            original_id: "examination.edd"
          })
        },
        // TODO deprecated
        "medicalexam.fetus.embryo_transfer_date": {
          view: () => ({
            original_id: "episode.embryo_transfer_date"
          })
        },
        // TODO deprecated
        "medicalexam.fetus.prev_ultrasound_exam_date": {
          view: () => ({
            orinal_id: "episode.prev_ultrasound_exam_date",
          })
        },
        'patient.name': {
          view: (reportData, id) => ({
            value: getPatientData(reportData)?.fullName,
            format: "string"
          })
        },
        'patient.lastname': {
          view: (reportData) => ({
            value: getPatientData(reportData)?.lastName,
            format: 'string'
          }),
          controller: (value, attrs) => ({ ...attrs, lastName: value })
        },
        'patient.firstname': {
          view: (reportData, id) => ({
            value: getPatientData(reportData)?.firstName,
            format: 'string'
          }),
          controller: (value, attrs) => ({ ...attrs, firstName: value })
        },
        'patient.middlename': {
          view: (reportData) => ({
            value: getPatientData(reportData)?.middleName,
            format: 'string'
          }),
          controller: (value, attrs) => ({ ...attrs, middleName: value })
        },
        'patient.title': {
          view: (reportData) => ({
            value: getPatientData(reportData)?.prefix,
            format: 'string'
          }),
          controller: (value, attrs) => ({ ...attrs, prefix: value })
        },
        'patient.dob': {
          view: (reportData) => ({
            value: reportData?.patient?.dob,
            format: 'date'
          }),
          controller: (value, attrs) => ({ ...attrs, dob: value })
        },
        'patient.age': {
          view: (reportData) => {
            return {
              value: safePatientAge(reportData?.patient?.dob, reportData?.examination_data?.examination_date),
              format: 'string',
              editable: false
            }
          }
        },
        'patient.address': {
          view: (reportData) => ({
            value: reportData?.patient?.address,
            format: 'string'
          }),
          controller: (value, attrs) => ({ ...attrs, address: value })
        },
        'patient.sex': {
          view: (reportData) => ({
            ...medicalHistoryOptions('patient.gender'),
            value: reportData?.patient.sex,
          }),
          controller: (value, attrs) => ({ ...attrs, sex: value })
        },
        'patient.gender': {
          view: () => ({
            original_id: 'patient.sex',
          }),
          controller: (value, attrs) => ({ ...attrs, sex: value })
        },
        'patient.ehr_id': {
          view: (reportData) => ({
            value: reportData?.patient?.ehr_id ?? reportData?.patient?.dicom_patient_id,
            format: 'string',
            editable: false
          })
        },
        'patient.mail': {
          view: (reportData) => ({
            value: reportData?.patient?.email_address,
            format: 'string'
          }),
          controller: (value, attrs) => ({ ...attrs, email_address: value })
        },

        'medical-history.medicalexam.mother.weight': {
          view: (reportData) => ({
            value: reportData.medical_history?.["medicalexam.mother.weight"]?.raw_value,
            units: "kg",
            format: "number"
          })
        },
        'medical-history.medicalexam.mother.height': {
          view: (reportData) => ({
            value: reportData.medical_history?.["medicalexam.mother.height"]?.raw_value,
            units: "cm",
            format: "number"
          })
        },
        'medical-history.medicalexam.mother.bmi': {
          view: (reportData) => ({
            // TODO make it editable and update the weight if updated
            value: computeBMI(
              reportData.medical_history?.["medicalexam.mother.weight"]?.raw_value,
              reportData.medical_history?.["medicalexam.mother.height"]?.raw_value
            ),
            editable: false,
            format: "number"
          })
        },
        // This is not working as those keys are not defined in reportData.medical_history
        // TODO ask Robert what is expected here
        'medical-history': {
          view: (reportData) => ({
            normal: !(reportData.medical_history?.risk_factors?.some(item => !!item.is_risky) || Object.values(reportData.medical_history?.teratogenic_risks || {}).some(item => !!item.risk_factor_id)),
            viewed: (!!reportData.medical_history?.risk_factors?.length || !!Object.values(reportData.medical_history?.teratogenic_risks || {}).length),
          })
        },
        'deprecated.medical-history': {
          view: (reportData) => ({
            value: reportData.medical_history,
          })
        },
        'measurements.unmapped': {
          view: (reportData) => {
            /* Unmapped Measurements */
            const unmapped = {}
            for (const [index, entry] of (reportData?.dynamic_measurements || []).entries()) {
              const v = {
                value: true,
                measurement_value: entry?.value,
                unit: entry?.unit,
                identifier: entry?.identifier,
                measurement: entry?.raw_measurement?.name,
                derivation: entry?.raw_derivation?.name,
                site: entry?.raw_site?.name,
                laterality: entry?.raw_laterality?.name,
              }
              unmapped[index] = v
            }
            return {
              value: unmapped,
              editable: false,
            }
          },
          controller: (value, attrs) => ({ ...attrs, email_address: value })
        },
        'dating.table': {
          view: () => ({
          })
        }
      }
    });
  }, [currentLanguage]);

  /* Define placeholders for the medical history items */
  useEffect(() => {
    console.log("Redefining medical history placeholders definition")
    const placeholdersToDefine = {};
    if (!examinationContext.medicalHistoryItems) return;
    Object.values(examinationContext.medicalHistoryItems).forEach(item => {
      const id = (
        item.text_id?.startsWith('patient.')
          ? item.text_id
          : `medical-history.${item.text_id}`
      );

      /* hardcoded fields */
      if ([
        "medical-history.medicalexam.mother.weight",
        "medical-history.medicalexam.mother.height"
      ].includes(id)) return;

      // TODO define those in directus
      const slugsMultiselect = [
        'medical-history.episode.antid',
        'medical-history.episode.antenatal_booking',
      ];

      /* Define these variable here so they get calculated only once */
      const tree = convertMedicalHistoryOptionsToTree(item.options);
      const fieldLabel = item.label[currentLanguage];
      const format = slugsMultiselect.includes(id)
        ? "mulitple"
        : itemFormatFromMHType(item.type)

      placeholdersToDefine[id] = {
        view: (reportData) => {
          return {
            value: reportData?.medical_history?.[item.text_id]?.value ?? item.default,
            raw_value: reportData?.medical_history?.[item.text_id]?.raw_value,
            is_risky: reportData?.medical_history?.[item.text_id]?.is_risky,
            label: getMHOptionLabel(item, reportData?.medical_history?.[item.text_id]?.value ?? item.default),
            fieldLabel,
            tree,
            type: item.type,
            format,
          }
        }
      }
    })
  }, [
    // medicalHistoryItems is a lookup data so it shouldn't change
    examinationContext.medicalHistoryItems,
    currentLanguage
  ])

  /* Load examination site and define logo.url placeholder */
  useEffect(() => {
    ResourceApi.getSite().then(resp => {
      const examinationSite = resp.data.data.find(s => s.id === examinationContext.examination?.site_id);
      setExaminationSite(examinationSite);
      dispatchPlaceholdersDefinition({
        event: "add",
        data: {
          "logo.url": {
            view: () => ({
              value: `data:image/png;base64, ${examinationSite?.header_image_base64}`
            })
          }
        }
      });
    });
  }, [examinationContext.examination?.site_id]);

  /* Define placehoders for imported data */
  const importedData = reportData?.examination_data?.imported_data || {};
  useEffect(() => {
    const placeholdersToDefine = {};
    console.log("Redefining imported data placeholders definition")
    Object.entries(importedData).forEach(([key, value]) => {
      const placeholdersKey = `examination.import.${key}`;
      // No need to redefine the placeholder data structure if it already exists
      if (placeholdersDefinition[placeholdersKey]) return;
      placeholdersToDefine[placeholdersKey] = {
        view: (reportData) => ({ value: reportData?.examination_data?.imported_data?.[key] })
      };
    });

    if (Object.keys(placeholdersToDefine).length > 0)
      dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
  }, [Object.keys(importedData).sort().join("|")]);

  const medicalHistoryItems = examinationContext?.medicalHistoryItems;

  // if this is long to calculate we can move it to a useMemo
  const fetusSexLabel = medicalHistoryItems?.["medicalexam.fetus.sex"]?.label?.[currentLanguage];
  /* Define placehoders for fetus.sex
   * This placeholder depends on the medical history item "medicalexam.fetus.sex"
   * label in the user language.
   * As this data is not comming from the reportData bundle we need to redine it every time.
   */
  useEffect(() => {
    console.log("Redefining fetus.sex placeholder definition")
    const placeholdersToDefine = {
      "fetus.sex": {
        view: (reportData) => {
          const fetusSexUserEdits = reportData?.report?.user_edits?.["fetus.sex"]
          return {
            label: fetusSexLabel,
            value: fetuses(reportData).map(f => fetusSexVisibility(reportData) === "hidden" ? "" : f?.fetus?.sex),
            comment: (fetusSexVisibility(reportData) === "masked") ? __("report.fetusSexHidden.comment") : fetusSexUserEdits?.comment || "",
            visible: fetusSexVisibility(reportData) === "visible" && fetusSexUserEdits?.visible,
            allowUpdatesWhenHidden: fetusSexVisibility(reportData) === "masked",
            showOptions: fetusSexVisibility(reportData) === "visible",
            tree: [
              {
                id: "",
                label: "",
              },
              {
                id: "male",
                label: __("report.fetusSex.male"),
              },
              {
                id: "female",
                label: __("report.fetusSex.female"),
              },
              {
                id: "unknown",
                label: __("report.fetusSex.unknown"),
              },
            ],
            format: "string"

          }
        }
      },
    };
    dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
  }, [fetusSexLabel]);

  /* Define placeholders for measurements */
  const measurementIds = useMemo(() => Object.values(reportData?.measurements?.fetus || {})
    .map(m => Object.keys(m))
    .concat(Object.keys(reportData?.measurements?.patient || {}))
    .flat()
    .filter(onlyUnique)
    .sort(),
    [reportData?.measurements]);

  useEffect(() => {
    console.log("Redefining measurement placeholders definition")
    const placeholdersToDefine = {};
    for (const measurementId of measurementIds) {
      const placeholdersKey = `measurement.${measurementId}`;
      placeholdersToDefine[placeholdersKey] = {
        view: (reportData, measurementData) => {
          const rawMeasurementData = {
            ...(measurementData?.measurements?.fetus || {}),
            0: measurementData?.measurements?.patient || {}
          }
          return Object.entries(rawMeasurementData).map(([fetus, measurements]) => {
            const fetusNumber = Number(fetus) || null;
            const measurement = measurements[measurementId];
            if (!measurement) return;
            return {
              ...getMeasurementObject(measurementId, measurement, fetusNumber),
              fetus: fetusNumber,
              editor: (data) => <EditBiometry id={measurementId} fetus={fetusNumber || "patient"} data={{ ...placeholder, ...data }} reportDataOptions={reportDataOptions} close={onEndEditing} />
            }
          });
        }
      }
    }

    if (Object.keys(placeholdersToDefine).length > 0)
      dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
  }, [
    measurementIds.join("|"),
    measurementsContext?.labels
  ]);


  /* Define placeholders for measurement estimation 
   * The definition of the placeholder is updated if new measurement estimation are received
   */
  const measurementEstimations = reportData?.v2_dating_values || [];
  const measurementEstimationsIds = useMemo(() => measurementEstimations.map(me => me.estimation)
    .filter(onlyUnique)
    .sort()
    .join("|"),
    [measurementEstimations]
  );

  useEffect(() => {
    console.log("Redefining measurement estimation placeholders definition")
    const episodeDatingSpecificEstimations = [
      "ga.lmp",
      "ga.conception_date",
      "ga.embryo_transfer",
      "ga.edd",
      "ga.prev_ultrasound",
    ];
    const placeholdersToDefine = {
      "ga.estimations": {
        view: () => ({
          value: measurementEstimations
            .map(({ estimation }) => `ga.${estimation}`)
            .concat(episodeDatingSpecificEstimations),
        }),
        editable: false
      }
    };
    /* measurementEstimations is a list of estimated ga by different methods (same methods can be used for different fetuses)
     * ## Example
     * { estimation: "ga.lmp", value: 240, fetus: 1, examination_id: 1, source: "calculated" }
     */
    measurementEstimations.forEach(me => {
      const { estimation } = me;
      const placeholdersKey = `ga.${estimation}`;
      // No need to redefine the placeholder data structure if it already exists
      // or it has been defined but not yet loaded
      if (placeholdersDefinition[placeholdersKey]) return;
      if (placeholdersToDefine[placeholdersKey]) return;
      placeholdersToDefine[placeholdersKey] = {
        view: (reportData) => {
          /* This view is pretty messy. TODO check if we can refactor it */
          const measurementEstimations = (reportData?.v2_dating_values || [])
            .filter(me => me.estimation === estimation);
          const dateObtained = examinationDate(reportData);

          const episodeDatingSpecificData = {
            "ga.lmp": { dateObtained: reportData?.episode?.lmp_date, cycleLength: reportData?.episode?.cycle_length },
            "ga.conception_date": { dateObtained: reportData?.episode?.conception_date, conceptionMethod: reportData?.episode?.conception_method },
            "ga.embryo_transfer": { dateObtained: reportData?.episode?.embryo_transfer_date, embryoTransferDay: reportData?.episode?.embryo_transfer_day },
            "ga.edd": { dateObtained: reportData?.episode?.estimated_delivery_date },
            "ga.prev_ultrasound": { dateObtained: reportData?.episode?.prev_ultrasound_exam_date, prevUltrasoundGa: reportData?.episode?.prev_ultrasound_ga },
          };

          const specificData = episodeDatingSpecificData[placeholdersKey] || {};


          return Object.fromEntries(measurementEstimations.map(me => {
            const {
              examination_id: examId,
              fetus,
              value,
              // TODO: source?
            } = me;

            const isAssigned = (
              // episodeGas are not related to any particular exam
              (
                examId == reportData?.v2_dating?.assigned_exam_id
                || Object.keys(episodeDatingSpecificData).includes(placeholdersKey)
              )
              && placeholdersKey === reportData?.v2_dating?.assigned_method_id
              && fetus == reportData?.v2_dating?.assigned_fetus
            )
            return [fetus ?? 0, {
              value,
              examId,
              dateObtained,
              method: estimation,
              isAssigned,
              fetus,
              ...specificData
            }]
          }))
        }
      };
    });

    if (Object.keys(placeholdersToDefine).length > 0)
      dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
  }, [measurementEstimationsIds]);

  /* Define placehodlers for identifier measurements */
  const identifierMeasurementIds = Object.keys(reportData?.identifier_measurements || {}).sort()
  useEffect(() => {
    const placeholdersToDefine = {};
    console.log("Redefining identifier measurements placeholders definition")
    for (const slug of identifierMeasurementIds) {
      const placeholdersKey = `identifier_measurement.${slug}`;
      if (placeholdersDefinition[placeholdersKey]) return;
      placeholdersToDefine[`identifier_measurement.${slug}`] = {
        // TODO how do we handle data from the measurement context
        // Check if they are not present in the reportData
        view: (reportData, _id, measurementData) => {
          const identifierEdits = measurementData?.measurement_edits?.identifier?.[slug] || {};
          const identifierData = reportData?.identifier_measurements?.[slug] || {};
          const valueEdits = Object.entries(identifierEdits || {}).filter(([k, _]) => !["visible", "comment", "value"].includes(k));

          for (const [measurement, { value }] of valueEdits) {
            if (!identifierData.hasOwnProperty(measurement)) identifierData[measurement] = {};
            identifierData[measurement].value = value;
          }
          return {
            measurements: identifierData,
            visible: identifierEdits?.visible ?? true,
            comment: identifierEdits?.comment ?? "",
            source: identifierData?.source ?? "dicom",
          };
        }
      }

      if (Object.keys(placeholdersToDefine).length > 0)
        dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
    }
  }, [identifierMeasurementIds.join("|")]);

  /* This is pure garbage, but I don't have time to refactor it
   * The main issue is it depends on ExaminationContext.instances 
   */
  /* Define placeholders for slides */

  const allSlidesMap = useMemo(() => {
    if (!Array.isArray(examinationContext.instanceViews)) return {};
    return examinationContext.instanceViews.reduce((map, view) => {
      map[view.id] = view;
      return map;
    }, {});
  }, [examinationContext.instanceViews]);

  const standards = useMemo(() => {
    if (!Array.isArray(examinationContext.instanceViews)) return [];
    const trimester = reportData?.examination_data?.trimester;
    return examinationContext.instanceViews
      .map(view => view.category?.[trimester])
      .filter((c) => c)
      .flat()
      .filter(onlyUnique);
  }, [examinationContext.instanceViews, reportData?.examination_data?.trimester]);

  useEffect(() => {
    console.log("Redefining placeholders definition for slides")
    if (!Array.isArray(examinationContext.instanceViews)) return;
    if (!Array.isArray(examinationContext.instances)) return;
    const allSlides = examinationContext.instanceViews.filter((view) => view?.id)
    const placeholdersToDefine = {
    };
    for (const standard of standards) {
      /* Put in the placeholder the mediaIds of the media's that are matching the standard
       * Example: I have a media is in addition to the protocol (slide 39)
       * The slide 39 is in the standard "sonio"
       * The placeholder "images.sonio" mediaIds will contains my media id
       */
      const standardKey = `images.${standard}`;
      placeholdersToDefine[standardKey] = {
        view: (reportData) => {
          const trimester = reportData?.examination_data?.trimester;
          return {
            mediaIds: examinationContext
              .instances
              .filter(media =>
                allSlidesMap[media.slideId]
                  ?.category
                  ?.[trimester]
                  ?.includes(standard))
              .map(media => media.id),
          }
        }
      }
    }
    for (const view of allSlides) {
      const viewSlug = (view.default_techno !== "us" ? view.default_techno + "." : "") + getSlugFromLabel(view.label?.["en"]);
      const medias = examinationContext.instances.filter(media => media.slideId === view.id);
      const placeholdersKey = `view.${viewSlug}`;

      // TODO handle view with conflicting slugs correctly
      // https://sonio.slack.com/archives/C04DQD871FX/p1728398910398849
      if (placeholdersToDefine[placeholdersKey]) {
        const message = `❌ Placeholder ${placeholdersKey} already defined.\n\
        The placeholder has been originaly defined on the view ${placeholdersToDefine[placeholdersKey].internal.view.id}.\n\
        Can not define it for view ${view.id}.\n\
        \n\
        Please check the view ${view.id} is not part of your protocol.\
        `;
        console.warn(message);
        continue;
      }

      placeholdersToDefine[placeholdersKey] = {
        internal: {
          view,
        },
        view: (reportData) => {
          return {
            normal: !reportData.checklist_items_with_statuses
              // List all the checklist items linked to this view
              ?.filter(c => checklistItemsMap[c.id]?.instance_view_id?.includes(view.id))
              // Check if there is any unusual
              ?.some(c => c?.status === "unusual"),
            viewed: !!medias.length,
            mediaIds: medias.map(media => media.id),
            techno: view.techno,
          }
        }
      }
    }

    dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
  }, [
    // This is a lookup information loaded only if the user is changed.
    // We can use it as a trigger to update the placeholders definition correctly
    examinationContext?.instanceViews,
    // As this placeholder depends on the examinationContext (Which is a bad practice but I don't have time to fix this)
    // I need to add a dependency to redefine it if a media is modified
    // This is a bad practice and should be fixed in V2
    examinationContext.instances,
    appContext?.preferences?.default_category.toLowerCase(),
    standards
  ]);

  /* Define placeholders for checklist items
   *
   * Unfortunately this placeholder is also messed up with the dependence
   * on the examination instances
   */
  const optionalViews = useMemo(() => examinationContext.instances
    .map(instance =>
      examinationContext.examinationInstanceViews
        .some(view => view.id === instance.slideId) ? false : instance.slideId)
    .filter(i => i),
    [examinationContext.instances, examinationContext.examinationInstanceViews]);

  useEffect(() => {
    console.log("Redefining placeholders definition for checklist items. Parent status system is not implemented as it was not working in previous system")
    const placeholdersToDefine = {};
    if (!Array.isArray(examinationContext.instanceViews)) return;
    if (!Array.isArray(examinationContext.instances)) return;

    for (const item of (Object.values(checklistItemsMap))) {
      const placeholdersKey = `checklist.item.${item.slug}`;
      // These are variable depending on examination instances.
      // We need to redefine when ever an image is modified
      const itemHasAnImage = examinationContext.instances
        .some(instance =>
          item.instance_view_id?.includes(instance.slideId))
        && (mandatoryChecklistSlugs.includes(item.slug) || optionalViews.includes(item.slideId));

      const itemShouldHaveAnImage = examinationContext.instanceViews
        .some(slide => item.instance_view_id?.includes(slide.id)) && mandatoryChecklistSlugs.includes(item.slug);

      placeholdersToDefine[placeholdersKey] = {
        internal: {
          item,
        },
        view: (reportData) => {
          return fetuses(reportData).map((f, fetusIndex) => {
            // This is super inefficient we need to find a way to optimize it
            const userDefinedStatus = reportData.checklist_items_with_statuses?.find(dynItem => dynItem.id === item.id && dynItem.examination_fetus_id == f.id)?.status || null;
            const itemStatus = inferChecklistItemStatus(itemHasAnImage, itemShouldHaveAnImage, { ...item, status: userDefinedStatus });
            const itemOrder = isChecklistItemPartOfTheExam(mandatoryChecklistSlugs, { ...item, status: userDefinedStatus });
            const userEdits = reportData?.report?.user_edits?.[`checklist.item.${item.slug}`]?.[f.dicom_id] || {};

            /* TODO if required we can also only duplicate item per fetus if origin = fetal
            * For the moment it is not required as checklist item displayed in the report are
            * defined in the report template/config
            */

            const newItem = {
              ...item,
              ...userEdits,
              visible: userEdits?.visible ?? true,
              examination_fetus_id: f.id,
              status: itemStatus,
              active: !!itemOrder,
              order: itemOrder,
              mandatory: mandatoryChecklistSlugs.includes(item.slug),
            }

            for (const [componentUID, comments] of Object.entries(autogeneratedChecklistComments[fetusIndex] || {})) {
              const key = `comment${componentUID === "fetal_anatomy" ? "" : "_" + componentUID}`;
              if (isNullOrUndefined(newItem[key]) || newItem[key] === false) {
                newItem[key] = comments?.find(comment => comment.data === `checklist.item.${item.slug}` && comment.attribute === "comment")?.content;
              }
            };

            return {
              ...newItem,
              group_id: item.assoc_checklist_item_checklist_item_group?.checklist_item_group_id,
              status: itemStatus,
              normal: itemStatus === 'usual',
              unusual: itemStatus === 'unusual',
              viewed: itemStatus !== 'non_visualized',
            }
          })
        }
      }
    }

    dispatchPlaceholdersDefinition({ event: "add", data: placeholdersToDefine });
  }, [
    // This is a lookup information loaded only if the user is changed.
    // We can use it as a trigger to update the placeholders definition correctly
    examinationContext?.instanceViews,
    // As this placeholder depends on the examinationContext (Which is a bad practice but I don't have time to fix this)
    // I need to add a dependency to redefine it if a media is modified
    // This is a bad practice and should be fixed in V2
    examinationContext.instances,
    optionalViews,
    checklistItemsMap,
    mandatoryChecklistSlugs,
    // TODO check it does not reload the placeholders definition too often
    autogeneratedChecklistComments
  ]);

  /* Load mandatory placeholders */

  useEffect(() => {
    const ids = [
      "examination.finding",
      "examination.indication",
      "ga.assigned.value",
      "examination.method",
      "examination.signed"
    ]
    dispatchPlaceholders("require", { ids, resolve: () => { } });
  }, []);

  const reportDropDownWithUserEdits = Object.keys(dynamicDropdowns).filter(slug => reportData?.report?.user_edits?.[slug]?.value).sort().join("|")
  useEffect(() => {
    Object.keys(dynamicDropdowns).forEach((slug) => {
      // the user edit value is not loaded in the first level of the collection so we load it
      // loadDynamicDropdownFullTree protects against multiple calls
      const user_edit = reportData.report?.user_edits?.[slug]?.value;
      if (!user_edit) return;

      // If single value and not in the loaded tree. Loading the full tree
      if (typeof user_edit !== "string" && !dynamicDropdowns[slug].tree.some(node => node.slug === user_edit))
        loadDynamicDropdownFullTree(slug);

      // If multi value we check against the keys
      const user_edits = Object.keys(user_edit);
      if (dynamicDropdowns[slug].tree.some(node => !user_edits.includes(node.slug)))
        loadDynamicDropdownFullTree(slug);
    })
  }, [reportDropDownWithUserEdits]);

  /* Define websocket handlers for liveness */
  useEffect(() => {
    const examinationId = examinationContext?.examination?.id;
    if (reportChannel) reportChannel.leave();

    if (socket && examinationId) {
      const channelTopic = `report:${examinationId}`;

      const channel = socket.channel(channelTopic);

      channel.on("update", (payload) => {
        switch (payload.resource_type) {
          case "report_data":
            return dispatchNewReportData({ event: "socket", data: payload.data });
          default:
            return;
        }
      });
      channel
        .join()
        .receive("ok", () => {
          setReportChannel(channel);
        })
        .receive("error", () => {
          console.error(
            `Join resource channel with topic ${channelTopic} failed`
          );
        });
    }
  }, [socket, examinationContext?.examination?.id]);

  useEffect(() => {
    // TODO: currently it's always parsing the report template, but we have to extend to other templates in the future (the MH one for example)
    // TODO: we should allow mandatory or not to be defined as a config and move away from the report template 
    const xmlParser = new DOMParser();
    setMandatoryChecklistSlugs(Object.values(xmlParser.parseFromString(templateBlueprint, "application/xml")
      .getElementsByTagName("checklist") || {})
      .reduce((slugs, elm) => [...slugs, ...(elm.getAttribute("items")?.split("|").filter(slug => !slugs.includes(slug)) || [])], [])
    );
  }, [templateBlueprint]);

  useEffect(() => {
    setDynamicDropdowns(dropdowns => deepMerge(dropdowns, reportData.report_dropdowns));
  }, [JSON.stringify(reportData.report_dropdowns)]);

  window.placeholdersDefinition = placeholdersDefinition;
  window.getPlacholderFromDefinition = (id) => {
    if (!placeholdersDefinition[id]) return;
    return placeholdersDefinition[id].view(
      reportData,
      measurementsContext?.measurementData || {}
    )
  }

  window.calculateAllPlaceholders = () => {
    return Object.entries(placeholdersDefinition).reduce((acc, [id, placeholder]) => {
      try {
        acc[id] = placeholder.view(reportData, measurementsContext?.measurementData || {});
        return acc;
      } catch (e) {
        console.error(`Error calculating placeholder ${id}`, e);
        return acc;
      }
    }, {});
  }

  /*
   * @param {Array} ids - Array of placeholders to require
   * @returns {Promise} - Promise that resolves when all placeholders are loaded. Resolve value is the loaded placeholders
   *
   * @example
   * // returns { "patient.firstname": {...}, "patient.lastname": {...}, "patient.dob": {...}, "patient.age": {...}, "patient.sex": {...}}
   * const placeholders = await requirePlaceholders(["patient.firstname", "patient.lastname", "patient.dob", "patient.age", "patient.sex"]);
   */
  const requirePlaceholders = (ids) => {
    return new Promise((resolve) => {
      dispatchPlaceholders("require", { ids, resolve });
    })
  }

  // TODO put these info inside debug panel
  window.requirePlaceholders = requirePlaceholders
  window.placeholders = placeholders;
  window.requiredPlaceholders = requiredPlaceholders;
  window.reportData = reportData;
  const apiVersion = "1.1";
  window.reportVersion = apiVersion;
  window.reportDataOptions = reportDataOptions;
  window.reportData = reportData

  return (
    <XMLTemplateContext.Provider
      value={{
        loaded: Object.values({ ...loadingStatus, ...additionalLoading } || {}).every(s => s),
        BIContext,
        dispatchNewReportData,
        placeholders,
        setPlaceholders,
        loadStaticReportOptions,
        customPlaceholders,
        setCustomPlaceholders,
        loadDynamicReportData,
        loadMeasurementsReportData,
        getCarryForwardWithProps,
        highlightedFields,
        setHighlightedFields,
        getHighligthedWithProps,
        loadDynamicDropdownFullTree,
        applyChanges,
        onEndEditing,
        onEndEditingDynamicDropdown,
        onEndEditingChecklist,
        onEndEditingDating,
        editingFieldId,
        startEditingField,
        setAssignedGa,
        revertAssignedGa,
        updateEpisode,
        applyAutomationTemplate,
        evaluateAutomationTemplate,
        generateAutomationTemplate,
        automationTemplateFieldsVisible,
        setAutomationTemplateFieldsVisible,
        componentChecklistAssoc,
        addComponentChecklistAssoc,
        removeComponentChecklistAssoc,
        requirePlaceholders,
        updateAutogeneratedChecklistComments,
        reportDataOptions,
        apiVersion
      }}
    >
      {children}
    </XMLTemplateContext.Provider >
  );
});

export default XMLTemplateContextProvider;
